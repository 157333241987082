import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import parse from "html-react-parser";

const useStyles = makeStyles(() =>
  createStyles({
    heading: {
      fontSize: "2rem",
      fontWeight: 700,
      marginBottom: "1rem",
    },

    section: {
      padding: "80px 50px",
      backgroundColor: "transparent",
      overflowX: "hidden",
      width: "100%",
      "@media(max-width: 768px)": {
        padding: "40px 20px",
      },
    },

    heading5: {
      fontSize: "48px",
      fontWeight: 600,
      lineHeight: "32px",
      fontFamily: "Montserrat",
      textTransform: "capitalize",
      textAlign: "center",
      marginBottom: "24px",
      "& > p": {
        fontFamily: "Montserrat"
      },
      "@media (max-width: 768px)": {
        fontSize: "18px",
        lineHeight: "26px",
        marginBottom: "16px",
      }
    },

    frameworks: {
      display: "flex",
      gap: "32px",
      marginTop: "28px",
      width: "2000px",
      animation: "scroll 20s linear infinite",
    },

    specialist: {
      display: "flex",
      gap: "32px",
      alignItems: "center",
      whiteSpace: "nowrap",
      justifyContent: "space-between",
    },
  })
);

type SpecialistProps = {
  description: string;
  specialist_logo_widget_items: SpecialistLogoWidgetItem[];
};

type SpecialistLogoWidgetItem = {
  id: number;
  attributes: {
    specialist_image: string;
  };
};

export default function GetCertified(props: any) {
  const styles = useStyles();
  const { description, framework_logos } = props;

  return (
    <Container className={styles.section}>
      <h5 className={styles.heading5}>{parse(description)}</h5>
      <div className={styles.frameworks}>
        <div className={styles.specialist}>
          {framework_logos.data.map((item: any) => (
            <Grid xs={12} md={4} lg={2} item key={item.id}>
              <img src={item.attributes.logo_framework} alt="framework" />
            </Grid>
          ))}
        </div>

        <div className={styles.specialist}>
          {framework_logos.data.map((item: any) => (
            <Grid xs={12} md={4} lg={2} item key={item.id}>
              <img src={item.attributes.logo_framework} alt="framework" />
            </Grid>
          ))}
        </div>
      </div>
    </Container>
  );
}
