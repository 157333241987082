import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, dropdownIcon } from "./assets";
import * as Yup from "yup";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  activeStep: number
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  signupSchema1: any;
  signupSchema3: any;
  initialValue1: any;
  initialValue3: any;
  signUpValue: any;
  localErrors: any;
  token: string;
  isEmailSend: boolean;
  time: any;
  seconds: any;
  activateMessage: any;
  isActivationLoading: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallIdone: any;
  createAccountApiCallIdthree: any;
  validationApiCallId: string = "";
  ResendEmailApiCallId: string = "";
  VerifyEmailApiCallId: string = "";
  activateAccountApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  timer: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      signupSchema1: Yup.object().shape({
        email: Yup.string().required("Email is Required").email("Invalid email address")
          .matches(new RegExp(configJSON.businessEmailValidation), "Please Enter Business Email"),
        fname: Yup.string()
                .trim()
                .matches(/^[A-Za-z\s]+$/, "First name must contain only letters and spaces")
                .required("First name is required"),
        lname: Yup.string()
                .trim()
                .matches(/^[A-Za-z\s]+$/, "Last name must contain only letters and spaces")
                .required("Last name is required"),
        company: Yup.string()
                .trim()
                .matches(/^[A-Za-z0-9.@&_\s]+$/, "Company name can contain only letters, numbers, and the special characters: @, &, . , and _")
                .required("Company name is required")
                .max(30, "Must be 30 characters or less"),
                
      }),
      signupSchema3: Yup.object({
        username: Yup.string()
          .required("Username is required")
          .matches(
            new RegExp(configJSON.userNameValidation),
            "Username must be 6 to 15 characters long, containing only letters, numbers and no special characters."
          ),
        password: Yup.string()
          .required("Password is required"),
        confirmpassword: Yup.string()
          .required("Confirm password is required")
          .oneOf([Yup.ref("password"), null], "Passwords must match"),
        acceptTerms: Yup.bool().oneOf(
          [true],
          "Please accept Terms & Conditions and Privacy Policy"
        )
      }),
      initialValue1: {
        email: "",
        fname: "",
        lname: "",
        company: "",
      },
      initialValue3: {
        username: JSON.parse(localStorage.getItem('signUpValue') as string)?.username || "",
        password: JSON.parse(localStorage.getItem('signUpValue') as string)?.password || "",
        confirmpassword: JSON.parse(localStorage.getItem('signUpValue') as string)?.confirmpassword || "",
        acceptTerms: JSON.parse(localStorage.getItem('signUpValue') as string)?.acceptTerms || false
      },
      signUpValue: {},
      localErrors: {},
      token: localStorage.getItem('token') || '',
      isEmailSend: false,
      time: {},
      seconds: 120,
      activateMessage: {
        message: '',
        success: true
      },
      isActivationLoading: false,
      // Customizable Area End
    };

    // Customizable Area Start

    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this)
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");
    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.switchCaseFunction(apiRequestCallId, responseJson)
      }
    }



    // Customizable Area End
  }

  // Customizable Area Start  

  async componentDidMount() {
    super.componentDidMount();
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
  }

  checkLogin() {
    const isLogin = JSON.parse(localStorage.getItem("isLogin") as string)
    if (isLogin) {
      this.props.navigation.navigate("Home")
    }
  }


  getValidation(responseJson: any) {
    this.arrayholder = responseJson.data;

    if (this.arrayholder.length !== 0) {
      let regexData = this.arrayholder[0];

      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(
          regexData.password_validation_regexp
        );
      }

      if (regexData.password_validation_rules) {
        this.setState({
          signupSchema3: Yup.object({
            username: Yup.string()
              .required("Username is required")
              .matches(
                new RegExp(configJSON.userNameValidation),
                "Username must be 6 to 15 characters long, containing only letters, numbers and no special characters."
              ),
            password: Yup.string()
              .required("Password is required").matches(regexData.password_validation_regexp,
                "Password must have minimum 8 characters, both uppercase and lowercase, at least one digit, and one special character."),
            confirmpassword: Yup.string()
              .required("Confirm password is required")
              .oneOf([Yup.ref("password"), null], "Passwords must match"),
            acceptTerms: Yup.bool().oneOf(
              [true],
              "Please accept Terms & Conditions and Privacy Policy"
            )
          })
        })
        this.setState({
          passwordHelperText: regexData.password_validation_rules
        });
      }

      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  switchCaseFunction(apiRequestCallId: any, responseJson: any) {
    switch (apiRequestCallId) {
      case this.validationApiCallId:
        this.getValidation(responseJson)
        break;

      case this.createAccountApiCallIdone:
        this.commonFunction(responseJson)
        break;


      case this.createAccountApiCallIdthree:
        if (responseJson.success) {
          localStorage.setItem('token', responseJson.token)
          localStorage.setItem('activeStep', JSON.stringify(0))
          localStorage.removeItem('signUpValue')
          this.props.navigation.navigate('VerifyEmail')
        }
        this.setState({ localErrors: responseJson.errors })
        break;

      case this.ResendEmailApiCallId:
        if (responseJson.success) {
          localStorage.setItem('token', responseJson.token)
          this.startTimer()
          this.setState({ isEmailSend: true })
        }
        break;


      case this.VerifyEmailApiCallId:
        this.handleAccountRes(responseJson)
        break;
      case this.activateAccountApiCallId:
        if (!responseJson.errors) {
          this.setState({
            activateMessage: {
              message: responseJson.meta.message,
              success: true,
            },
            isActivationLoading: false,
          })
        } else {
          this.setState({
            activateMessage: {
              message: responseJson.errors[0].token,
              success: false
            },
            isActivationLoading: false
          })
        }
        break;
    }
  }

  commonFunction(responseJson: any) {
    if (responseJson.success) {
      this.setState({ token: responseJson.token })
      localStorage.setItem('token', responseJson.token)
      localStorage.setItem('activeStep', responseJson.step)
      this.props.setActiveStep(responseJson.step)
    }
    this.setState({ localErrors: responseJson.errors })
  }

  handleAccountRes(responseJson: any) {
    if (responseJson.data.success) {
      this.props.navigation.navigate("Login")
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallIdone = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }


  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  getbody(step: number) {
    const { fname, lname, email, company, username, password, confirmpassword } = this.state.signUpValue
    const userData = {
      email: email ?? ''
    }
    const httpBodyone = {
      "data": {
        "type": "email_account",
        "attributes": {
          "first_name": fname,
          "last_name": lname,
          "email": email,
          "company_name": company
        }
      }
    }
    const httpBodythree = {
      "data": {
        "type": "email_account",
        "url": `https://${window.location.host}/activate_account`,
        "token": this.state.token,
        "attributes": {
          "user_name": username,
          "password": password,
          "password_confirmation": confirmpassword
        }
      }
    }

    if (step === 1) {
      localStorage.setItem("userData", JSON.stringify(userData))
      return { httpBody: httpBodyone, endpoint: configJSON.accountsAPiEndPointone }
    } else if (step === 2) {
      return { httpBody: httpBodythree, endpoint: configJSON.accountsAPiEndPointtwo }
    } 
  }


  handleRegister(step: number) {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const httpBody = this.getbody(step)?.httpBody

    const endpoint = this.getbody(step)?.endpoint

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (step === 1) {
      this.createAccountApiCallIdone = requestMessage.messageId;
    } else {
      this.createAccountApiCallIdthree = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  activateAccount(token: string) {

    this.setState({ isActivationLoading: true });

    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getAccountMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.activateAccountApiCallId = getAccountMsg.messageId;

    getAccountMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activateAccountAPiEndPoint + '?token=' + token
    );

    getAccountMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAccountMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getAccountMsg.id, getAccountMsg);
  }
  
  handleNext(value: any) {
    this.setState({ localErrors: {} })
    this.setState({ signUpValue: { ...this.state.signUpValue, ...value } })
    localStorage.setItem('signUpValue', JSON.stringify({ ...this.state.signUpValue, ...value }))
    let previewActiveStep = this.props.activeStep + 1;
    this.handleintialValue(value)
    this.handleRegister(previewActiveStep)
  }

  handleintialValue(value: any) {
    switch (this.props.activeStep) {
      case 0:
        this.setState({
          initialValue1: {
            email: value?.email,
            fname: value?.fname,
            lname: value?.lname,
            company: value?.company,
          },
        })
        break;
      case 1:
        this.setState({
          initialValue3: {
            username: value?.username,
            password: value?.password,
            confirmpassword: value?.confirmpassword,
            acceptTerms: value?.acceptTerms,
          },
        })
        break;
    }
  }

  CustomDropdownIcon = (props: any) => {
    return <img {...props} alt="" style={{ marginRight: "15px" }} src={dropdownIcon} />
  }

  handleResendMail() {
    const UserData = JSON.parse(localStorage.getItem("userData") as string)
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const httpBody = {
      "data": {
        "token": localStorage.getItem('token'),
        "email": UserData?.email,
        "url": `https://${window.location.host}/activate_account`
      }
    };

    const requestMessageEmail = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ResendEmailApiCallId = requestMessageEmail.messageId;
    requestMessageEmail.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendEmailAPiEndPoint
    );

    requestMessageEmail.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageEmail.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessageEmail.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessageEmail.id, requestMessageEmail);
  }

  handleVerifyMail() {
    const UserData = JSON.parse(localStorage.getItem("userData") as string)
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const httpBody = {
      "data": {
        "email": UserData?.email
      }
    };

    const requestMessageVerify = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.VerifyEmailApiCallId = requestMessageVerify.messageId;
    requestMessageVerify.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyEmailAPiEndPoint
    );

    requestMessageVerify.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageVerify.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessageVerify.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessageVerify.id, requestMessageVerify);
  }

  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds == 0) {
      clearInterval(this.timer);
    }
  }

  secondsToTime(secs: any) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    if (minutes === 0 && seconds === 0) {
      this.setState({ isEmailSend: false })
    }

    let obj = {
      "h": hours,
      "m": minutes < 10 ? `0${minutes}` : `${minutes}`,
      "s": seconds < 10 ? `0${seconds}` : `${seconds}`
    };
    return obj;
  }

  isGoNext(errors: any, values: any) {
    const isError = Object.keys(errors || {})?.length === 0
    const isErrorlocal = Object.keys(this.state.localErrors || {})?.length === 0
    const isValue = Object.values(values)?.every((item: any) => item?.length !== 0)
    return isError && isValue && isErrorlocal ? "#43D270" : "#B1B3C3"
  }

  handleSaveOnBlur = (e: any) => {
    const { name, value } = e.target
    this.setState({ initialValue3: { ...this.state.initialValue3, [name]: value } }, () => {
      localStorage.setItem('signUpValue', JSON.stringify({ ...this.state.initialValue3, [name]: value }))
    })
  }

  // Customizable Area End
}
