// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../cfnzdpuassuranceandverification/src/CfnzdpuassuranceandverificationController";

const configJSON = require("./config");
const staticRoutes = [
  { page_link: "/", title: "Home" },
  { page_link: "/pricing", title: "Pricing" },
  { page_link: "/offerings", title: "Offerings" },
  { page_link: "/carbonzeroed-software", title: "Carbonzeroed Software" },
  { page_link: "/advisory-services", title: "Advisory Services" },
  { page_link: "/marketplace", title: "Marketplace" },
  { page_link: "/implementation-services", title: "Implementation Services" },
  { page_link: "/events", title: "Events" },
  { page_link: "/insights-and-trends", title: "Insights & Trends" },
  { page_link: "/toolkit", title: "Toolkit" },
  { page_link: "/privacy-policy", title: "Privacy Policy" },
  { page_link: "/terms-and-conditions", title: "Terms & Conditions" },
  { page_link: "/company", title: "about-us" },
  { page_link: "/about-us", title: "about-us" },
  { page_link: "/careers", title: "Careers" },
  { page_link: "/free-assessment", title: "Free Assessment" },
  { page_link: "/documentation", title: "Documentation" },
  { page_link: "/how-it-works", title: "How it works" },
  { page_link: "/faqs", title: "Faqs" },
  { page_link: "/contact-us", title: "Contact Us" },
  { page_link: "/login/primary", title: "Login" },
  { page_link: "/signup", title: "Sign Up" },
  { page_link: "/success/email", title: "Success Mail" },
  { page_link: "/success/contact", title: "Success Contact" },
  { page_link: "/forgot-password", title: "Forgot Password" },
  { page_link: "/NewPassword", title: "New Password" },
  { page_link: "/ResendEmail", title: "Resend Email" },
  { page_link: "/VerifyEmail", title: "Verify Email" },
  { page_link: "/activate_account", title: "Activate Account" },
  { page_link: "/job-openings-list", title: "Job Openings List" },
  { page_link: "/job-application", title: "Job Application" },
  { page_link: "/job-success", title: "Job Success" },
  { page_link: "/industries", title: "Industries" }
];

interface DynamicRoute {
  page_link: string;
  title: string;
}

interface DynamicRoutesResponse {
  pages: DynamicRoute[]
}

interface Props { }

interface S {
  dynamicRoutes: DynamicRoute[];
}

interface SS { }

export default class PageNotFoundController extends BlockComponent<
  Props,
  S,
  SS
> {
  getDynamicRoutesApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      dynamicRoutes: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      // Receive api responses
      if (apiRequestCallId === this.getDynamicRoutesApiCallId) {
        this.handleGetDynamicRoutesResponse(responseJson);
      }
    }
  }
  
  async componentDidMount(): Promise<void> {
    this.getDynamicRoutes();
  }

  getDynamicRoutes = async () => {
    this.getDynamicRoutesApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodGet,
      endPoint: configJSON.dynamicRoutesApiEndPoint,
    }); 
  }

  handleGetDynamicRoutesResponse = (response: DynamicRoutesResponse) => {
    if (response.pages) {
      this.setState({ dynamicRoutes: response.pages });
    }
  }

  getPageTitle = (dynamicRoutes: DynamicRoute[]) => {
    const currentPathName = window.location.pathname && window.location.pathname.toLowerCase();

    // Static Path route checking
    const staticRoute = staticRoutes.filter((item) => currentPathName.includes(item.page_link));
    if (staticRoute && staticRoute.length > 0) {
      return staticRoute[0].title;
    }

    // Dynamic Path route checking
    if (dynamicRoutes && dynamicRoutes.length > 0) {
      const dynamicRoute = dynamicRoutes?.filter((dItem) => dItem.page_link === currentPathName);
      if (dynamicRoute && dynamicRoute.length > 0 && dynamicRoute[0].title) {
        return dynamicRoute[0].title;
      }
    }

    return "";

  }

}
// Customizable Area End