import React, { useState } from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    styled
} from "@material-ui/core/styles";
import { Grid, Container, Box, Button, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import MinimizeIcon from '@material-ui/icons/Minimize';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { removeTags } from '../landingpage/LpDifferentFromOther';
import ArrowOutwardIcon from '@material-ui/icons/ArrowForward';
import { Link } from "react-router-dom";
import SwitchButton from '../../../dashboard/src/common/SwitchButton.web';
import { CustomTooltip } from '../../../multiplelandingpage2/src/pages/ReportsFramwork.web';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },

        PartBox: {
          minWidth: '180px',
          "&:hover $starter": {
            background: "#43d270",
            },
        },
        partBox2: {
            minWidth: '232px',
        },
        partBox3: {
          minWidth: '229px',
        },
        mainDiv: {
            paddingInline: "106px",
        },

        headerStyle: {
            marginBottom: "47px",
        },

        Heading1: {
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            textAlign: "center",
            marginBottom: "70px",
        },

        GridStyle: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
        },

        startbtn: {
          backgroundColor: "#ebedfc",
            height: "56px",
          borderRadius: "6px",
          padding: "10px 16px",
            fontSize: "15px",
            marginTop: "50px",
            fontFamily: 'Lato',
            "&:hover": {
                backgroundColor: "#43D270",
                boxShadow: "none",
            },
        },
        linkStyle: {
            textDecoration: 'none',
          color: '#475569',
          display: 'flex',
          alignItems: 'center',
          fontWeight: 700,
          "&:hover": {
            color: "#000"
          }
        },

        IconStyle: {
            marginLeft: "8px",
        },
        ArrowIconStyle: {
            marginLeft: "8px",
            transform: "rotate(-45deg)"
        },

        contentStyle: {
            fontSize: "34px",
            fontWeight: 700,
            fontFamily: "lato",
            lineHeight: "46px",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            textAlign: "center",
            marginTop: "50px",
            marginBottom: "50px",
        },

        startbtn2: {
            backgroundColor: "#ffffff",
            border: "1px solid #000000",
            fontSize: "15px",
            height: "56px",
            borderRadius: "8px",
            marginTop: "50px",
        },

        boxStyle: {
            display: "flex",
            marginBottom: "60px",
            justifyContent: "center",
        },

        table: {
            marginBottom: "70px",
            "& .MuiTableFooter-root .MuiTableRow-root .MuiTableCell-root": {
                border: "none",
            },
            "& .MuiTableFooter-root .MuiTableRow-root .MuiTableCell-root MuiTableCell-footer MuiTableCell-sizeSmall":
            {
                borderBottom: "none",
            },
            "& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root ": {
                border: "none",
            },
        },
        table2: {
            marginBottom: "70px",
            maxWidth: '1230px',
            overflowX: 'auto',
            "& .MuiTableFooter-root .MuiTableRow-root .MuiTableCell-root": {
                border: "none",
            },
            "& .MuiTableFooter-root .MuiTableRow-root .MuiTableCell-root MuiTableCell-footer MuiTableCell-sizeSmall":
            {
                borderBottom: "none",
            },
            "& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root ": {
                border: "none",
            },
        },

        starter: {
          marginBottom: "8px",
          borderRadius: "4px",
          backgroundColor: "#ECFBF1",
          color: "#101010",
          fontSize: "18px",
          fontFamily: "Montserrat",
          padding: "10px",
          transition: "background-color 0.3s",
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "#43D270",
            color: "#1d3825"
          }
        },

        Growth: {
            marginBottom: "8px",
        },

        Impact: {
            marginBottom: "8px",
        },

        powerStyle: {
          fontSize: "inherit",
          fontWeight: 700,
            fontFamily: "lato",
            lineHeight: "32px",
        },

        btnStyle: {
            fontSize: "15px",
            fontWeight: 500,
            fontFamily: "lato",
            lineHeight: "18px",
            textAlign: "center",
            justifyContent: "center",
            paddingBottom: '50px'
        },

        rowStyle: {
            textAlign: "center",
          paddingTop: '32px'
        },

        rowStyle2: {
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
        },

        headerContent: {
            marginBottom: "32px",
            minHeight: '26px'
        },

        boxHeader: {
            marginBottom: "50px",
            textAlign: "center",
            height: "26px",
            '@media (max-width:600px)': {
                height: 'auto',
            }
        },

        minimizeBtn: {
            display: "none",
        },
        addBtn: {
            display: "inline-block",
        },
        rowHover: {
            "&:hover": {
                backgroundColor: "#f5f5f5",
            },
        },
        rowStyle1: {
            "&:hover": {
                backgroundColor: "#f5f5f5",
            },
            textAlign: "center",
        },
        label: {
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: "12px",
            lineHeight: "19.2px",
            color: "#34373A",
            marginTop: '7px'
        },
        switchContainer: {
            display: 'flex',
            gap: '25px',
          justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          marginBottom: '20px',
          "@media (max-width:600px)": {
            marginBottom: "40px"
          }
        },
      switchWrapper: {
        position: "relative",
        display: "inline-flex",
        padding: 0,
        border: "none",
        backgroundColor: "#EBEDFC",
        borderRadius: "50px",
        "& input": {
          display: "none"
        },
        "& label": {
          fontSize: "16px",
          textTransform: "uppercase",
          fontFamily: "Lato",
          fontWeight: 700,
          zIndex: 1,
          minWidth: "100px",
          lineHeight: "32px",
          cursor: "pointer",
          borderRadius: "50px",
          padding: "10px 36px",
        }
      },
      highlighter: {
        position: "absolute",
        top: "4px",
        left: "4px",
        width: "calc(50% - 4px)",
        height: "calc(100% - 8px)",
        borderRadius: "50px",
        backgroundColor: "#43D270",
        transition: "transform 0.3s ease-in-out",
      },
      headingBlock: {
        textAlign: 'center',
        padding: '60px 0',
      },
      background: {
        position: "absolute",
        width: "100%",
        height: "400px",
        background: "linear-gradient(0deg, #FFF 0%, #E5EAFF 100%)",
        zIndex: -1,
      },
      heading: {
        fontSize: "48px",
        fontWeight: 800,
        lineHeight: "67px",
        letterSpacing: "2%",
        "@media (max-width:600px)": {
          fontSize: "32px",
          lineHeight: "40px",
        }
      },
      subHeading: {
        fontSize: "18px",
        color: "#595959",
        lineHeight: "25px",
        marginBottom: "20px",
        "@media (max-width:600px)": {
          fontSize: "14px",
          lineHeight: "20px",
        }
      },
      featureName: {
        color: "#595959",
        fontSize: "18px",
        fontWeight: 500,
        fontFamily: "Montserrat",
        textTransform: "uppercase",
      },
      infoIconStyle: {
        opacity: "0.8",
        cursor: "pointer",
        height: "38px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "9px",
        color: "#B1B5C3",
      },
    })
);
const HeadingText = styled(Typography)({
    color: '#34373A',
    fontSize: '34px',
  fontWeight: 600,
    fontFamily: 'Montserrat',
    lineHeight: '56px',
    maxWidth: "700px",
    '@media (max-width:600px)': {
        fontSize: '28px',
        lineHeight: '30px',
    }
});
const SubTittle = styled(Typography)({
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'Montserrat',
    lineHeight: '13px',
})

const ContentStyle = styled(Typography)({
  fontSize: '18px',
  fontWeight: 500,
  color: "#6d6d6d",
  fontFamily: 'Montserrat',
    lineHeight: '26px',
})
const HiddenBox1 = styled(Box)({
    '@media(max-width: 900px)': {
        display: 'none',
    }
})
const HiddenBox2 = styled(Box)({
    '@media(min-width: 900px)': {
        display: 'none',
    }
})

export const PlanLabelRenderer = ({ plan, classes, index = 0, filter }: { plan: any, classes: any, index?: number, filter: boolean }) => {
    let filterText = "";
    let currencyType = "";
    let amountExists = false;
    if (filter) {
      // Filter: per month
      amountExists = plan.attributes.amount !== null && plan.attributes.amount >= 0;
    } else {
        // Filter: per year
        amountExists = plan.attributes.amount_yearly !== null && plan.attributes.amount_yearly >= 0;
    }

    if (amountExists) {
      filterText = filter ? "MONTH" : "YEAR"
      currencyType = plan.attributes.currency_type;
    } 

    return <>
      <div className={classes.starter} style={{fontWeight: 600}}>{plan.attributes.level}</div>
        <HeadingText>
          <span
            className={classes.powerStyle}>{currencyType}</span>{filter ? plan.attributes.amount : plan.attributes.amount_yearly}
        </HeadingText>
        <ContentStyle>{filterText}</ContentStyle>
    </>
}

export const PlanActionRenderer = ({ styleForwidthStart, button_type, styleForwidthContact, buttonLink, classes, buttons_name }: { button_type: any, styleForwidthContact: any, styleForwidthStart?: any, buttonLink: any, classes: any, buttons_name?: any }) => {
    const widthStyle = buttons_name == "primary" ? { width: styleForwidthStart } : { width: styleForwidthContact };
  return <Button className={classes.startbtn} style={widthStyle}>
        <Link to={buttonLink} className={classes.linkStyle}>
            {buttons_name}
        </Link>
    </Button>
}

export const PlanCheckIconRenderer = ({ data, feature, planIndex, classes }: { data: any, feature: any, planIndex: number, classes: any }) => {
    const isfeature: boolean = data.includes(feature.name)
    if (isfeature
    ) {
      return (
        <svg width="32" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.5003 2.66797C9.15366 2.66797 3.16699 8.65464 3.16699 16.0013C3.16699 23.348 9.15366 29.3346 16.5003 29.3346C23.847 29.3346 29.8337 23.348 29.8337 16.0013C29.8337 8.65464 23.847 2.66797 16.5003 2.66797ZM22.8737 12.9346L15.3137 20.4946C15.127 20.6813 14.8737 20.788 14.607 20.788C14.3403 20.788 14.087 20.6813 13.9003 20.4946L10.127 16.7213C9.74033 16.3346 9.74033 15.6946 10.127 15.308C10.5137 14.9213 11.1537 14.9213 11.5403 15.308L14.607 18.3746L21.4603 11.5213C21.847 11.1346 22.487 11.1346 22.8737 11.5213C23.2603 11.908 23.2603 12.5346 22.8737 12.9346Z"
            fill="#43D270"/>
        </svg>
      )
    } else {
      return (
        <svg width="32" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.5003 2.66797C9.15366 2.66797 3.16699 8.65464 3.16699 16.0013C3.16699 23.348 9.15366 29.3346 16.5003 29.3346C23.847 29.3346 29.8337 23.348 29.8337 16.0013C29.8337 8.65464 23.847 2.66797 16.5003 2.66797ZM20.9803 19.068C21.367 19.4546 21.367 20.0946 20.9803 20.4813C20.7803 20.6813 20.527 20.7746 20.2737 20.7746C20.0203 20.7746 19.767 20.6813 19.567 20.4813L16.5003 17.4146L13.4337 20.4813C13.2337 20.6813 12.9803 20.7746 12.727 20.7746C12.4737 20.7746 12.2203 20.6813 12.0203 20.4813C11.6337 20.0946 11.6337 19.4546 12.0203 19.068L15.087 16.0013L12.0203 12.9346C11.6337 12.548 11.6337 11.908 12.0203 11.5213C12.407 11.1346 13.047 11.1346 13.4337 11.5213L16.5003 14.588L19.567 11.5213C19.9537 11.1346 20.5937 11.1346 20.9803 11.5213C21.367 11.908 21.367 12.548 20.9803 12.9346L17.9137 16.0013L20.9803 19.068Z"
            fill="#CBD5E1"/>
        </svg>
      )
    }
}


const PricingTable = (props: any) => {
  const {page_heading, page_sub_heading, subscribe_levels, pricing_features} = props;
    const classes = useStyles();
    const numberOfLevels = subscribe_levels?.data.length;
    const styleMap: { [key: number]: string } = {
        1: classes.PartBox,
        2: classes.PartBox,
        3: classes.PartBox,
        4: classes.partBox2,
    };

    let styleFor3 = styleMap[numberOfLevels] || classes.partBox3;
  const styleScroll = numberOfLevels > 5 ? classes.table2 : classes.table;
    const btnWidthStart = numberOfLevels > 3 ? "160px" : "180px";
    const btnWidthContact = numberOfLevels > 3 ? "164px" : "184px";
  const [filter, setFilter] = useState(true)

    const handleChange = () => {
      setFilter(!filter)
    }

  return (
    <div>
      <div className={classes.background}/>
      <Container>
        <div className={classes.headingBlock}>
          <p className={classes.subHeading}>{page_sub_heading}</p>
          <h2 className={classes.heading}>{page_heading}</h2>
        </div>
        <Grid container className={classes.GridStyle}>
          <div className={classes.switchContainer}>
            <div className={classes.switchWrapper}>
              <input
                id="monthly"
                type="radio"
                name="switch"
                checked={filter}
                onChange={handleChange}
              />
              <input
                id="yearly"
                type="radio"
                name="switch"
                data-test-id={"yearly"}
                checked={!filter}
                onChange={handleChange}
              />
              <label htmlFor="monthly">Monthly</label>
              <label htmlFor="yearly">Yearly</label>
              <span className={classes.highlighter} style={{transform: filter ? 'none' : 'translateX(100%)'}}></span>
            </div>
          </div>

          <HiddenBox1>
            <Box style={{display: "flex", gap: "20px"}} className={styleScroll}>
              <Box style={{minWidth: '200px'}}>
                <Box style={{height: "180px"}} className={classes.rowStyle}></Box>
                {pricing_features &&
                  pricing_features.map((feature: any, findex: number) => (
                    <Typography
                      key={`keyr_${findex}`}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "60px",
                      }}
                    >
                      <p
                        className={classes.featureName}>{feature.name.charAt(0).toUpperCase() + feature.name.slice(1)}</p>
                      <CustomTooltip
                        arrow
                        placement="bottom-start"
                        title={feature.info}
                      >
                        <p className={classes.infoIconStyle}>
                          <InfoOutlinedIcon/>
                        </p>
                      </CustomTooltip>
                    </Typography>
                  ))}
              </Box>
              {subscribe_levels.data &&
                subscribe_levels.data.map((plan: any, index: number) => {
                  return (
                    <Box key={`label_key${index}`} className={styleFor3}>
                      <Box
                        style={{width: "100%", height: "180px"}}
                        className={classes.rowStyle}
                      >
                        <Grid className={classes.headerStyle}>
                          <PlanLabelRenderer
                            filter={filter}
                            classes={classes}
                            plan={plan}
                            index={index}
                          />
                        </Grid>
                      </Box>
                      {pricing_features &&
                        pricing_features.map((feature: any, findex: number) => (
                          <Typography
                            key={`ddskey_${findex}`}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              height: "60px",
                              alignItems: "center",
                            }}
                          >
                            <p style={{display: "flex", alignItems: "center", height: "60px"}}>
                              <PlanCheckIconRenderer
                                feature={feature}
                                classes={classes}
                                planIndex={index}
                                data={plan?.attributes?.subscribe_level_features}
                              />
                            </p>
                          </Typography>
                        ))}
                      <Box className={classes.btnStyle}>
                        {plan?.attributes?.button_name ?
                          <PlanActionRenderer
                            buttons_name={plan?.attributes?.button_name}
                            classes={classes}
                            buttonLink={plan?.attributes?.button_link}
                            styleForwidthStart={btnWidthStart}
                            styleForwidthContact={btnWidthContact}
                            button_type={plan?.attributes?.button_type ?? 'primary'}
                          /> : ''}
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </HiddenBox1>

          <HiddenBox2>
            {subscribe_levels.data && subscribe_levels.data.map((plan: any, index: number) => {
              return <Grid key={`grid_col_mobile${index}`}>
                <Box className={classes.boxHeader}>
                  <PlanLabelRenderer
                    filter={filter}
                    classes={classes}
                    plan={plan}
                    index={index}
                  />
                </Box>
                <Table size="small" aria-label="a dense table">
                  <TableBody>
                    {pricing_features && pricing_features.map((feature: any, findex: number) => (
                      <TableRow key={`key_row${findex}`}>
                        <TableCell component="th" scope="row">
                          {feature.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <CustomTooltip
                            arrow
                            placement="bottom-start"
                            title={feature.info}
                          >
                            <InfoOutlinedIcon/>
                          </CustomTooltip>
                        </TableCell>
                        <TableCell align="center">
                          <PlanCheckIconRenderer
                            feature={feature}
                            classes={classes}
                            planIndex={index}
                            data={plan?.attributes?.subscribe_level_features}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Box className={classes.boxStyle}>
                  {
                    plan?.attributes?.button_name ?
                      <PlanActionRenderer
                        classes={classes}
                        buttons_name={plan?.attributes?.button_name}
                        buttonLink={plan?.attributes?.button_link}
                        styleForwidthStart={"180px"}
                        styleForwidthContact={"184px"}
                        button_type={plan?.attributes?.button_type ?? 'primary'}
                      /> : ''
                  }
                </Box>
              </Grid>
            })
            }
          </HiddenBox2>
        </Grid>
      </Container>
    </div>
  )

}

export default PricingTable;