import React, { CSSProperties } from "react";

// Customizable Area Start
import {
    Box,
    Paper,
    Typography,
    Container
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close'
import { logo, email } from "./assets";
import { Link } from 'react-router-dom';
const configJSON = require("./config");
// Customizable Area End

import EmailAccountRegistrationController, {
    Props,
} from "./EmailAccountRegistrationController";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class VerifyEmail extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
    }
    async componentDidMount() {
        // Customizable Area Start
        this.handleVerifyMail();
        // Customizable Area End
    }
    render() {
        return (
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <Box style={webStyle.emailContainer as CSSProperties}>
                    <Box style={webStyle.logocontainer as CSSProperties}>
                        <img src={logo} alt="logo" />
                        <Link to="/login/secondary">
                            <CloseIcon style={{ color: '#FFFF' }} />
                        </Link>
                    </Box>
                    <Paper elevation={3} style={webStyle.emailverify as CSSProperties}>
                        <img src={email} alt="email" />
                        <Typography style={webStyle.emailmaintitle as CSSProperties}>
                            {
                                this.state.isEmailSend ? "Success" : "Verify your Email"
                            }

                        </Typography>
                        <Typography style={webStyle.emailsecondtitle as CSSProperties}>
                            {
                                this.state.isEmailSend ?
                                    "Thank you Email Resent SuccessFully check your email and click on the verification link to confirm your account"
                                    : "Thank you, check your email and click on the verification link to confirm your account"
                            }
                        </Typography>
                        <Typography style={webStyle.emailresend as CSSProperties}>
                            {
                                this.state.isEmailSend ? `Resend in ${this.state.time.m} : ${this.state.time.s}` :
                                    <>
                                        {configJSON.resendMailText}
                                        < button
                                            type="submit"
                                            style={{ color: '#43D270', border: 'none', backgroundColor: '#FFFF', cursor: 'pointer' }}
                                            onClick={() => this.handleResendMail()}>
                                            Resend
                                        </button>
                                    </>
                            }
                        </Typography>
                    </Paper>
                </Box>
            </ThemeProvider >
        );
    }
}

const webStyle = {
    emailContainer: {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: '#34373A',
        height: "100vh",
    },
    logocontainer: {
        position: "relative",
        bottom: "18%",
        width: "90%",
        display: "flex",
        justifyContent: "space-between",
    },
    emailverify: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "40px",
        gap: "32px",
        maxWidth: "450px",
        height: "400px",
        left: "495px",
        top: "250px",
        background: "#FFFFFF",
        borderRadius: "16px"
    },
    emailmaintitle: {
        fontFamily: 'Montserrat',
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "125%",
        textAlign: "center",
        letterSpacing: "0.2px",
        color: "#0F172A",
    },
    emailsecondtitle: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: "17px",
        lineHeight: "26px",
        textAlign: "center",
        letterSpacing: "0.005em",
        color: "#34373A",
    },
    emailresend: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "160%",
        textAlign: "center",
        color: "#0F172A",
    }
};
