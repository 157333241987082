import React from "react";
import { styled } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import parse from "html-react-parser";

export default function HeadingContent(props: any) {
  const { main_header, header, page_heading, page_sub_heading, image } = props;
  const StyledHeadingContent = styled("div")({
    paddingTop: "60px",
    "@media(max-width: 768px)": {
      paddingTop: "32px",
    },
  });

  const StyledContainerMain = styled("div")({
    marginTop: "60px",
    borderRadius: "24px",
    backgroundColor: "white",
    overflow: "hidden",
    "@media(max-width: 768px)": {
      marginTop: "32px",
    },
  });
  const StyledBackgroundImage = styled("div")({
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: "calc(50% - 30px)",
    "@media(max-width: 768px)": {
      position: "relative",
      width: "95%",
      right: "unset",
      margin:"20px 10px"
    },
    });

  const StyledHeading = styled("h2")({
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "48px",
    textTransform: "capitalize",
    marginTop: "16px",
    "@media(max-width: 768px)": {
      fontSize: "24px",
      lineHeight: "32px",
     },
    "@media (max-width:900px) and (min-width:400px)":{
      maxWidth:"380px",
      width:"fit-content"
    }
  });

  const StyledTextBox = styled("div")({
    padding: "80px 50px",
    "@media(max-width: 768px)": {
      padding: "24px",
    },
  });

  const StyledBadge = styled("div")({
    color: "#6f66e9",
    fontFamily: "Montserrat",
    borderRadius: "4px",
    padding: "4px 12px",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 600,
    backgroundColor: "#e0e0fa",
    display: "inline-block",
  });

  const StyledImage = styled("img")({
    width: "100%",
    height: "100%",
    borderStartEndRadius: "20px",
    objectFit: "cover",
    "@media(max-width: 768px)": {
      borderRadius: "0px",
      height: "88%",
    },
    });

  const StyledTitle = styled("div")({
    fontSize: "48px",
    fontWeight: 800,
    fontFamily: "Lato",
    lineHeight: "68px",
    textTransform: "capitalize",
    "@media(max-width: 768px)": {
      fontSize: "28px",
      lineHeight: "36px",
    },
  });

  const StyledBodyText = styled("p")({
    marginTop: "12px",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#595959",
    "@media(max-width: 768px)": {
      fontSize: "14px",
      lineHeight: "24px",
    },
  });

  const StyledContainer = styled(Container)({
    textAlign: "center",
  });

  const StyledBackground = styled("div")({
    position: "absolute",
    width: "100%",
    height: "1000px",
    zIndex: -1,
    background: "linear-gradient(0deg, #fff 0%, #e5eaff 100%)",
  });

  return (
    <>
      <StyledBackground />
      <StyledHeadingContent>
        <Container>
          <div>
            <StyledContainer>
              <StyledTitle>{page_heading}</StyledTitle>
              <StyledBodyText>{page_sub_heading}</StyledBodyText>
            </StyledContainer>
          </div>

          <StyledContainerMain>
            <Grid container style={{ position: "relative" }}>
              <Grid item xs={12} md={6}>
                <StyledTextBox>
                  <StyledBadge>{main_header}</StyledBadge>
                  <StyledHeading>{parse(header)}</StyledHeading>
                </StyledTextBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledBackgroundImage>
                  <StyledImage src={image} alt="placeholder" />
                </StyledBackgroundImage>
              </Grid>
            </Grid>
          </StyledContainerMain>
        </Container>
      </StyledHeadingContent>
    </>
  );
}
