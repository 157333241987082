import React, {ReactElement, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Grid,
  Container,
  Typography,
  Box,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@material-ui/core';
import parse from 'html-react-parser';
import Pagination from '@material-ui/lab/Pagination';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {ic_search} from "../../blocks/DynamicContent/src/assets";

const useStyles = makeStyles({
  widgetHeading: {
    "& > *": {
      fontFamily: "Lato",
      fontSize: "28px",
      fontWeight: 600,
      lineHeight: "39.2px",
      letterSpacing: "0.5px",
      color: "#000000",
    }
  },
  mainContainerStyle: {
    alignItems: "center",
    paddingX: "20px",
    paddingBottom: '20px'
  },

  genericCard: {
    background: "#FFFFFF",
    borderRadius: "16px",
    width: "100%",
    '& :hover': {
      transform: "scale(1)",
    },
    cursor: "pointer"
  },

  imageCard: {
    width: "392px",
    height: "399px",
    maxWidth: "100%",
    background: "#FFFFFF",
    borderRadius: "14px",
    margin: "0 auto 30px auto",
    justifyContent: "center",
    alignItems: "center",
    display: 'flex',
    overflow: 'hidden',
  },
  images: {
    maxWidth: "100%",
    height: "100%",
    objectFit: "cover",
    transition: "all ease 0.5s",
    '&:hover': {
      transform: "scale(1.1)",
    },
  },
  images1: {
    maxWidth: "100%",
    objectFit: "cover",
    transition: "all ease 0.5s",
    '&:hover': {
      transform: "scale(1.1)",
    },
  },
  jobCategory: {
    color: "#34373A",
    lineHeight: "30px",
    fontSize: "20px",
    fontWeight: "bold",
    fontFamily: 'Montserrat',
  },
  gcContent: {
    padding: "0 10px",
  },
  noDecoration: {
    textDecoration: "none !important",
    cursor: 'pointer',
  },
  title: {
    color: "#fff",
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    width: "fit-content",
    padding: "6px 16px 6px 16px",
    borderRadius: "4px",
    marginBottom: "15px",
  },
  text: {
    height: "70px",
    color: "#000000",
    fontFamily: 'Lato',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "22px",
    width: "100%",
    display: "-webkit-box",
    lineHeight: "35.2px",
    textOverflow: "ellipsis",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  description: {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "25.6px",
    color: "#4F4F4F",
    marginTop: "10px",
    height: "80px",
  },
  cardMetaDataStyle1: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    marginBottom: "7px"
  },
  ctaButton: {
    fontFamily: "Lato",
    fontWeight: 700,
    color: "#fff",
    backgroundColor: "#43D270",
    padding: "12px 32px",
    borderRadius: "8px",
    transition: "all 0.4s",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#897ecc",
    }
  },
  cardMetaDataStyle2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "7px"
  },
  authorName: {
    color: "#34373A",
    fontFamily: 'Lato',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    marginBottom: "14px",
    flex: 1,
  },
  date: {
    color: "#34373A",
    fontFamily: 'Lato',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    flex: 1,
  },
  cardButtonWr: {
    textAlign: "right",
  },
  cardButton: {
    minHeight: "48px",
    background: "#43D270",
    borderRadius: "8px",
    boxShadow: "none",
    display: "inline-flex",
    minWidth: "131px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    textDecoration: "none !important",
    color: "#000",
    textTransform: "uppercase",
    fontFamily: 'Lato',
    fontWeight: 500,
    lineHeight: "normal",
  },
  tagButton: {
    minHeight: "32px",
    background: "#F3F3F3",
    borderRadius: "32px",
    boxShadow: "none",
    display: "inline-flex",
    minWidth: "72px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    textDecoration: "none !important",
    color: "#727272",
    fontSize: '14px',
    fontWeight: 600,
    textTransform: "capitalize",
  },
  tagPaidButton: {
    minHeight: "32px",
    background: "#E0E0FC",
    borderRadius: "32px",
    boxShadow: "none",
    display: "inline-flex",
    minWidth: "72px",
    fontFamily: "Montserrat",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    textDecoration: "none !important",
    color: "#7166F1",
    fontSize: '14px',
    textTransform: "capitalize",
    fontWeight: 600,
  },
  ctaInput: {
    backgroundColor: "#FAFAFB",
    width: "300px",
    maxWidth: "100%",
    fontSize: "15px",
    borderRadius: "8px !important",
    border: "2px solid #737373 !important",
    overflow: "hidden",
    "& .MuiInputBase-root-630": {
      borderRadius: "8px !important",
      backgroundColor: "#F3F3F3",
      border: "2px solid #737373 !important"
    },
    "& .MuiOutlinedInput-input": {
      padding: "15px",
    },
    '& input ': {
      height: "48px",
      boxSizing: "border-box",
      padding: "13px 20px",
      fontFamily: "Lato",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "22.4px",
      letterSpacing: "0.5px",
      color: "#000",
    },
    "@media(max-width: 600px)": {
      width: "100%",
      maxWidth: "100%",
    },
    "@media(max-width: 360px)": {
      width: "100%",
    },
  },

  sortInput: {
    width: "180px",
    maxWidth: "100%",
    height: "38px",
    borderRadius: "8px !important",
    overflow: "hidden",
    paddingLeft: "75px",
    marginLeft: '0',
    fontFamily: 'Lato !important',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22.4px",
    color: "#000",
    '& div:focus': {
      backgroundColor: "rgba(0, 0, 0, 0) !important",
    },

  },
  emptyResultContainer: {
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '110px'
  },
  emptyresutdescription: {
    fontFamily: 'Montserrat !important',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "36px",
    textAlign: "center",
    color: "#34373A",
    maxWidth: '600px',
    marginTop: '50px'
  },
  sortLabel: {
    top: '-1px',
    left: '12px',
    zIndex: 1,
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22.4px",
    letterSpacing: "0.1px",
    color: "#5E5E5E !important",
  }
  ,
  searchSortLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    "@media(max-width: 600px)": {
      flexDirection: "column",
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  },
  sortFieldStyle: {
    position: "relative"
  },
  pagination: {
    color: "red",
    '& .Mui-selected': {
      background: '#43D270 !important',
      height: "52px",
      width: "52px",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "22.4px",
      color: "#000000",
    },
    '& > ul > li': {
      marginRight: '15px',
    },
    '& > ul > li > button': {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "22.4px",
      color: "#000000",
    },
  }
})

interface PropTypes {
  page?: string,
  header?: string,
  enableMetaTags?: boolean,
  enableButton?: boolean,
  enablePaymentTags?: boolean,
  limit?: number,
  data?: any,
  viewDetails?: any,
  detailPath?: string
  handleChangeSearch?: any,
  handleChangeSort?: any,
  handlePageChange?: any,
  pageId?: any,
  isLoading?: boolean,
}

const getTagColors: any = {
  Environmental: "#43D270",
  Social: "#7166F1",
  Governance: "#F96C30",
  Flexible: "#F96C30",
  OnSite: "#43D270",
  Online: "#43D270",
  Remote: "#7166F1"
}

export function parseContentFromHTMLString(htmlString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Extract the content inside the <p> tag
  let content = "";
  if (doc) {
    content = doc.querySelector('p')?.textContent ?? "";
  }
  return content;
}

export function convertTo12HourFormat(dateString: string) {
  if (!dateString) {
    return "";
  }
  // Parse the date string into a Date object
  let date = new Date(dateString);

  // Extract hours and minutes
  let hours = date.getUTCHours();
  let minutes = date.getUTCMinutes();

  // Determine AM or PM
  let ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Format hours and minutes to always be two digits
  const hoursString = hours < 10 ? '0' + hours : hours;
  const minutesString = minutes < 10 ? '0' + minutes : minutes;

  // Construct the formatted time string
  let formattedTime = hoursString + ':' + minutesString + ' ' + ampm;

  return formattedTime;
}

export function formatDateToString(dateString: string) {
  if (!dateString) {
    return "";
  }
  // Parse the date string into a Date object
  let date = new Date(dateString);

  // Define an array of month names
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Extract the day, month, and year from the Date object
  let day = date.getUTCDate();
  let month = monthNames[date.getUTCMonth()];
  let year = date.getUTCFullYear();

  // Construct the formatted date string
  let formattedDate = `${day} ${month}, ${year}`;

  return formattedDate;
}

const BlogListingMarketplaceSection = ({
                                         header,
                                         enableMetaTags,
                                         enableButton,
                                         pageId,
                                         limit,
                                         data,
                                         viewDetails = () => {
                                         },
                                         handleChangeSearch,
                                         handlePageChange,
                                         handleChangeSort,
                                         isLoading,
                                       }: PropTypes) => {
  const postLimit = limit ?? 10000;
  const styles = useStyles();
  const detailPagePath = window.location.pathname?.toLowerCase();
  const listOnePage = limit || 6;
  const totalPage = Math.ceil(data?.widget_sub_items_count / listOnePage);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleScroll = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSelectClick = () => {
    setIsMenuOpen((prevIsMenuOpen: any) => !prevIsMenuOpen);
  };

  const handleMenuItemClick = () => {
    setIsMenuOpen(false);
  };
  return (
    <Container maxWidth="lg" className={styles.mainContainerStyle}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        style={{paddingBottom: "30px"}}
      >
        <Grid item lg={7} xs={12}>
          <Box className={styles.widgetHeading}>
            {header && parse(header.toString())}
          </Box>
        </Grid>
        <Grid item lg={5} xs={12}>
          <Box className={styles.searchSortLabel}>
            <TextField
              data-test-id='handle-change-function'
              variant="standard"
              className={styles.ctaInput}
              onChange={(e: any) => handleChangeSearch(e.target.value, pageId)}
              placeholder='Search'
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <img src={ic_search} alt='search' height={30} width={31}/>
                  </InputAdornment>
                ),
                disableUnderline: true
              }}
            />
            <FormControl
              className={styles.sortFieldStyle}
            >
              <InputLabel
                shrink={false}
                className={styles.sortLabel}>
                Sort by
              </InputLabel>
              <Select
                data-test-id='handle-change-sort'
                variant="standard"
                className={styles.sortInput}
                onChange={(e: any) => handleChangeSort(e.target.value, pageId)}
                disableUnderline
                defaultValue="DESC"
                open={isMenuOpen}
                onOpen={handleSelectClick}
                onClick={handleMenuItemClick}
                onClose={handleSelectClick}
                IconComponent={() => <ExpandMoreIcon style={{color: "#000"}}/>}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null,
                  disableScrollLock: true
                }}
              >
                <MenuItem value="DESC">Newest</MenuItem>
                <MenuItem value="ASC">Old</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      {
        !isLoading && data?.widget_sub_items?.data?.length === 0 ?
          <Box className={styles.emptyResultContainer}>
            {/*<img src={empty} alt="no result" style={{width: '276.14px', height: '255.29px'}}/>*/}
            <Typography className={styles.emptyresutdescription}>
              Sorry, we couldn’t find any results for that search.
              Try again with a different key word.
            </Typography>
          </Box>
          :
          <>
            <Grid container spacing={4} alignItems='center'>
              {data?.widget_sub_items?.data && data?.widget_sub_items?.data.slice(0, postLimit).map((item: any, index: any): ReactElement => {
                const fullDescription = parseContentFromHTMLString(item?.attributes?.marketplace_description);
                const slicedDescription = `${fullDescription.slice(0, 74)}...`;
                const blogPostDate = formatDateToString(item?.attributes?.created_at);
                return <Grid item xs={12} sm={4} container key={`s_item_${index}`}>
                  <Box
                    data-test-id="tesetid12"
                    className={styles.genericCard}
                    data-set-test='genericCard'
                    onClick={() => {
                      viewDetails(item.id, detailPagePath);
                    }}>
                    <Box
                      className={styles.imageCard}
                    >
                      {item?.attributes?.marketplace_image &&
                          <img src={item.attributes.marketplace_image}
                               className={`${enableButton ? styles.images1 : styles.images}`}
                               alt=""
                          />
                      }
                    </Box>
                    <Box className={styles.gcContent}>
                      <Typography
                        variant='h3'
                        className={styles.title}
                        style={{
                          visibility: item?.attributes?.resource_tag_id ? "visible" : "hidden",
                          backgroundColor:
                            getTagColors[item?.attributes?.resource_tag_id] || "#F96C30"
                        }}
                      >
                        {item?.attributes?.resource_tag_id}
                      </Typography>
                      <Typography variant='h4'
                                  className={styles.text}>{item.attributes.marketplace_title}</Typography>
                      <Typography className={styles.description}>{slicedDescription}</Typography>
                      <div className={styles.cardMetaDataStyle1}>
                        <div className={styles.ctaButton}
                             onClick={(e) => {
                               e.stopPropagation()
                               window.open(item.attributes.marketplace_link)
                             }}>
                          Explore Now
                        </div>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              })}
            </Grid>
            <Box display="flex" justifyContent="center" pt={5} pb={5}>
              {data?.widget_sub_items?.data?.length !== 0 && data?.widget_sub_items?.data !== undefined &&
                  <Pagination
                      count={totalPage}
                      shape="rounded"
                      className={styles.pagination}
                      onChange={(event, value) => handlePageChange(value)}
                  />
              }
            </Box>
          </>
      }
    </Container>
  );
}
export default BlogListingMarketplaceSection;