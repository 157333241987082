import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {RouteComponentProps} from "react-router-dom"
interface SubItems1 {
    data:{
        id:string,
        attributes:{
            title:string,
            description:string,
            full_description:string
            image:string
        }
    }[]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:{[key:string]:string};
  page_heading:string;
  headings:string[];
  widget_sub_items:SubItems1;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabContent:string | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CarbonzeroedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  search = window.location.search;
  params = new URLSearchParams(this.search);
  title = this.params.get('title');
  unlisten?: () => void;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      tabContent: this.title || this.props.headings[0]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.unlisten = this.props.history.listen(() => {
      window.location.reload();
    });
  }
  handleTabs = (data:string | null) => {
    this.setState({tabContent:data})
  }
  async componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }
  // Customizable Area End
}