import React from "react";
import { styled } from "@material-ui/core/styles";
import { Grid, Modal, Box } from "@material-ui/core";
import Layout from "../Layout.web";
import DashboardController, { Props } from "../DashboardController";
import { Link } from "react-router-dom";
import LogEsgFactorCard from "../common/LogEsgFactorCard.web";
import SimplePagination from "../common/SimplePagination.web";
import MemberModalWeb from "../common/MemberModalWeb.web";
import ConfirmModal from "../common/ConfirmModal.web";
import CustomSnackbar from "../common/CustomSnackbar.web";
import MonthYearSelect from "../common/MonthYearSelect.web";
import { setStorageData } from "../../../../framework/src/Utilities";

const LogsContainer = styled("div")({
	width: "100%",
	minHeight: "calc(100vh - 185px)",
	padding: "32px 32px 21px 32px"
});

const TabWithSelect = styled(Box)({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	marginBottom: "10px",
	'@media(max-width: 700px)': {
		flexWrap: "wrap",
	},
})

const Tabs = styled("div")({
	display: "flex",
	flexWrap: "wrap",
	width: "100%",
	gap: "8px",
	justifyContent: "flex-start",
	alignItems: "center",
	marginBottom: "5px",
	'@media(max-width: 700px)': {
		gap: "4px",
	},
});

const Tab = styled(Link)({
	display: "flex",
	justifyContent: "center",
	padding: "8px 12px",
	alignItems: "center",
	gap: "10px",
	flexShrink: 0,
	borderRadius: "8px",
	backgroundColor: "#FFF",
	color: "#7D7D7F",
	fontSize: "12px",
	height: "32px",
	cursor: "pointer",
	textDecoration: "none",
	fontFamily: "Lato",
	fontStyle: "normal",
	fontWeight: 400,
	letterSpacing: "0.2px",
});

const ActiveTab = styled("div")({
	display: "flex",
	justifyContent: "center",
	padding: "8px 12px",
	alignItems: "center",
	gap: "8px",
	flexShrink: 0,
	borderRadius: "8px",
	backgroundColor: "#ECFBF1",
	color: "#329E54",
	fontSize: "12px",
	fontWeight: 700,
	cursor: "pointer",
	fontFamily: "Lato",
	fontStyle: "normal",
	letterSpacing: "0.2px",
});

const TabContent = styled(Grid)({
	width: " calc(100% + 14px) !important",
	marginLeft: "-12px", // "-22px",
	paddingTop: "0 !important",
	paddingBottom: "0 !important"
});

const TextInfo = styled('p')({
	color: "#7D7D7F",
	fontFamily: "Lato",
	fontStyle: "normal",
	letterSpacing: "0.2px",
	fontSize: "14px",
	padding: "0 23px",
});

const MonthYearRight = styled('div')({
	marginRight: "10px",
});

const OrgBaseUrl = "/measure/log-your-esg-factors";


const logsTabs = [
	{
		id: 1,
		label: "All",
		link: "/all"
	},
	{
		id: 10,
		label: "Core",
		link: "/core"
	},
	// {
	// 	id: 2,
	// 	label: "Organization",
	// 	link: "/organization"
	// },
	{
		id: 3,
		label: "Environment",
		link: "/environment"
	},
	{
		id: 4,
		label: "Social",
		link: "/social"
	},
	{
		id: 5,
		label: "Governance",
		link: "/governance"
	},
	{
		id: 7,
		label: "Completed",
		link: "/completed"
	},
	{
		id: 8,
		label: "Archived",
		link: "/archived"
	},
	// {
	// 	id: 9,
	// 	label: "Recommended",
	// 	link: "/recommended"
	// },
];

export default class LogEsgFactors extends DashboardController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}


	async componentDidMount() {
		const cat = this.props.navigation?.getParam('category');
		const selectedCat = cat ? cat : "All";
		this.fetchEsgFactorData(selectedCat, 1)
		this.getTasks()
	}

	async componentDidUpdate(prevProps: any) {
		if (prevProps.navigation.getParam('category') !== this.props.navigation.getParam('category')) {
			const cat = this.props.navigation.getParam('category');
			const selectedCat = cat ? cat : "All";
			this.fetchEsgFactorData(selectedCat, 1)
			this.getTasks()
		}
	}


	handleOnCardClick = async (card: any) => {
		let taskId = JSON.parse(sessionStorage.getItem("taskId") as string);
		const taskIdFromLocalStorage = this.getTaskId();
		if (!taskId) {
			taskId = taskIdFromLocalStorage;
		}
		if (card.card_title === "Energy Consumption") {
			const energyConsumptionPayload = {
				taskId: taskId,
				materialFactorId: card.material_factor_id,
				categoryId: card.category_id,
				coreFactorId: card.core_factor_id,
				cardId: card.id,
				status: card.status,
			};
			setStorageData("energyConsumptionPayload", JSON.stringify(energyConsumptionPayload));
			this.navigateToEnergyConsumptionPage();
			return;
		} else if (card.card_title === "Shipping & Freight") {
			const shipFreightPayload = {
				taskId: taskId,
				materialFactorId: card.material_factor_id,
				categoryId: card.category_id,
				coreFactorId: card.core_factor_id,
				cardId: card.id,
				status: card.status,
			};
			setStorageData("shipFreightPayload", JSON.stringify(shipFreightPayload));
			this.navigateToShippingFreightPage();
			return;
		}
		this.props.navigation.navigate('EsgFactorsDetails', {
			id: taskId,
			mid: card.material_factor_id,
			cid: card.category_id,
			coreFacId: card.core_factor_id,
			card_id: card.id,
			status: card.status,
		})
	}

	onChangeFilterDate = (e: any) => {
		const monthYear = e.target.value;
		this.setCardFilterDate(monthYear)
		let catName = ""
		const cat = this.props.navigation ? this.props.navigation.getParam('category') : 'all';
		catName = cat?.[0].toUpperCase() + cat?.slice(1)
		this.fetchEsgFactorData(catName, 1, monthYear)
	}

	render() {

		const { esgFactorData, isCardAction, showDeleteAlert, deleteUpdateStatus, showArchiveAlert, archiveUpdateStatus, esgCardDelete, esgCardArchive } = this.state;
		const activeTab = this.props.navigation ? this.props.navigation.getParam('category') : 'all';

		return <Layout>
			<LogsContainer>
				<TabWithSelect>
					<Tabs>
						{logsTabs.map((tab) => {
							return activeTab === tab.label.toLowerCase() ?
								<ActiveTab
									data-test-id="tab_link"
									key={`active_tab_${tab.id}`}
								>
									{tab.label}
								</ActiveTab>
								:
								<Tab
									data-test-id="tab_link"
									onClick={() => this.handleOnEsgFactorTabChange(tab.label)}
									key={`inactive_tab${tab.id}`}
									to={OrgBaseUrl + tab.link}
								>
									{tab.label}
								</Tab>

						})}
					</Tabs>
					<MonthYearRight>
						<MonthYearSelect
							onChange={this.onChangeFilterDate}
							value={this.state.cardFilterDate}
						/>
					</MonthYearRight>
				</TabWithSelect>
				<TabContent spacing={0} container>
					{esgFactorData.cards && esgFactorData.cards.map((card: any) => {
						return <Grid item xs={12} sm={6} md={4}>
							<LogEsgFactorCard
								testId="log_factors"
								cardStatus={card.status}
								category={card.category}
								heading={card.card_title}
								description={card.card_description}
								buttonLabel={`${this.cardBtnLabel(card)} `}
								buttonLink={"/"}
								userType={isCardAction}
								assign={card.assigned_to}
								assigneeImage={card.assigned_to_profile_image}
								onSelectOption={(value: string) => this.handleOnEsgFactorMoreOption(value, card)}
								onClick={() => this.handleOnCardClick(card)}
								isDisabled={card.card_answered}
								core_factor_id={this.coreFactorIdRenderName(card.core_factor_id)}
							/>
						</Grid>
					})}

					{esgFactorData.cards?.length === 0 && <TextInfo>No cards found.</TextInfo>}
				</TabContent>
				<Modal open={this.state.isShowMember} onClose={this.handleCloseMember} style={webStyle.mamberModalStyle} data-test-id="modalClose">
					<MemberModalWeb onAssign={this.onAssignCard} onClose={this.handleCloseMember} id={""} navigation={this.props.navigation} />
				</Modal>
			</LogsContainer>
			<SimplePagination
				count={esgFactorData.total_cards || 0}
				onChange={this.handleOnPaginationChange}
			/>

			{esgCardDelete.confirm === "open" && <ConfirmModal
				dataTestId="confirm_modal"
				description={"Are you sure to remove this task from the list?"}
				confirmBtnLabel={"Yes"}
				cancelBtnLabel={"No"}
				onCancel={() => this.deleteEsgFactorCardCancel()}
				onConfirm={() => this.deleteEsgFactorCard()}
				isOpen={esgCardDelete.confirm === "open"}
				navigation={undefined}
				id={"confirm-modal-id"} />}

			{esgCardArchive.confirm === "open" && <ConfirmModal
				dataTestId="confirm_archive_modal"
				description={"Are you sure to archive this task from the list?"}
				confirmBtnLabel={"Yes"}
				cancelBtnLabel={"No"}
				onCancel={() => this.archiveEsgFactorCardCancel()}
				onConfirm={() => this.archiveEsgFactorCard()}
				isOpen={esgCardArchive.confirm === "open"}
				navigation={undefined}
				id={"confirm-modal-id"} />}

			{
				this.state.esgCardUnArchive.openConfirmation && (
					<ConfirmModal
						dataTestId="confirm_unarchive_modal"
						description="Are you sure to unarchive this task from the list?"
						confirmBtnLabel="Yes"
						cancelBtnLabel="No"
						onCancel={() => this.handleConfirmationModalToUnArchiveCards(false)}
						onConfirm={() => this.handleConfirmationModalToUnArchiveCards(true)}
						isOpen={this.state.esgCardUnArchive.openConfirmation}
						navigation={undefined}
						id={"confirm-modal-id"} 
					/>
				)
			}

			{deleteUpdateStatus == "success" && <CustomSnackbar
				// data-test-id='snackbar-alert'
				open={showDeleteAlert}
				dataTestId="custom_snackbar"
				onClose={() => this.setState({
					showDeleteAlert: false
				})}
				status={"success"}
				AlertLabel="The task is successfully deleted"
			/>}

			{archiveUpdateStatus == "success" && <CustomSnackbar
				dataTestId="custom_archive_snackbar"
				open={showArchiveAlert}
				onClose={() => this.setState({
					showArchiveAlert: false
				})}
				status={"success"}
				AlertLabel="This task is successfully deleted"
			/>}

			{this.state.esgFactorCardDeleteError && <CustomSnackbar
				dataTestId="custom_archive_snackbar"
				open={showArchiveAlert}
				onClose={() => this.setState({
					showArchiveAlert: false
				})}
				status={"success"}
				alert
				AlertLabel="Mandatory cards cannot be deleted"
			/>}
			<CustomSnackbar
				dataTestId="unarchive_snackbar"
				open={this.state.esgCardUnArchive.success}
				onClose={() => this.setState({
					esgCardUnArchive: {
						...this.state.esgCardArchive,
						success: false,
					}
				})}
				status={"success"}
				AlertLabel="The task is successfully unarchived"
			/>
		</Layout>

	}
}

const webStyle = {
	mamberModalStyle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}
};