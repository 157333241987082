import React from 'react';
import Grid from "@material-ui/core/Grid";
import { Container, Box, Chip, CircularProgress } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import RelatedPosts from './RelatedPosts.web';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import parse from 'html-react-parser';
import { removeTags } from '../../../blocks/DynamicContent/src/landingpage/LpDifferentFromOther';
import { IMAGE_HEIGHT, useStyles, HeadingText, socialIcons, getSocialLink, ProgressContainer } from './BlogDetails.web';

interface PropTypes {
    tag?: string;
    tagColor?: string;
    heading?: string;
    date?: string;
    author?: string;
    eventTime?: string;
    description?: string;
    button1Label?: string;
    button1Link?: string;
    button2Label?: string;
    button2Link?: string;
    primaryButtonBg?: string;
    secondaryButtonBg?: string;
    primaryButtonColor?: string;
    enableButtons?: boolean;
    postType?: string,
    data?: any,
    viewDetails?: any,
    isLoading?: boolean;
}

const EventBlogDetails = ({ enableButtons, postType, data, viewDetails, isLoading }: PropTypes) => {
    const classes = useStyles({ enableButtons, postType });
    const attributes = data?.attributes;
    const postId = data?.id;
    const fullPath = window.location.pathname;
    const pathParts = fullPath.split("/");
    const jobOpeningsListPath = `/${pathParts[1]}`;
    const tagCompanyAuthorInfo = [];
    attributes?.resource_tag_id && tagCompanyAuthorInfo.push(attributes.resource_tag_id);
    attributes?.compay && tagCompanyAuthorInfo.push(attributes.compay);
    attributes?.author && tagCompanyAuthorInfo.push(`By ${attributes.author}`);

    const tagCompanyAuthorContent = (
        <ul className={classes.tagCompanyAuthorUl}>
            {tagCompanyAuthorInfo.map(info => {
                return <li key={info}>{info}</li>
            })}
        </ul>
    );

    if (isLoading) {
        return (
            <ProgressContainer>
              <CircularProgress color="inherit" />
            </ProgressContainer>
        );
    }

    return (
        <Box>
            <Box className={classes.topGradiant}></Box>
            <Container>
                {tagCompanyAuthorContent}
                <Grid className={classes.firstGrid}>
                    <Container className={classes.miniContainer}>
                        {attributes?.event_title &&
                            <Box className={classes.HeadingStyle}>
                                <HeadingText>{removeTags(attributes.event_title)}</HeadingText>
                            </Box>
                        }

                    </Container>

                    {attributes?.event_description &&
                        <Box>
                            {jobOpeningsListPath !== "/job-openings-list" && <Box className={classes.ImageStyle}>
                                {
                                    attributes?.event_image &&
                                    <Box className={classes.imageContainer}>
                                        <img 
                                            src={attributes.event_image} 
                                            alt="blog image" 
                                            className={classes.ImageStyle1} 
                                        />
                                    </Box>
                                }
                                <Box 
                                    className={classes.paragraphStyle}
                                    style={{ minHeight: attributes?.event_image ? IMAGE_HEIGHT: "unset", }}
                                >
                                    {parse(attributes.event_description)}
                                </Box>
                            </Box>}
                        </Box>
                    }

                    <Box className={classes.IconBoxStyle}>
                        <Box className={classes.tagNameContainer}>
                            {attributes?.tag_names.map((tagName: string) => {
                                return <Typography className={classes.tagName}>{tagName}</Typography>
                            })}
                        </Box>
                        <Box className={classes.IconStyle2}>
                            {attributes?.social_media_links.map((iconName: string) => {
                                const socialUrl = getSocialLink(iconName);
                                return (<IconButton onClick={() => window.open(socialUrl, "_blank")} className={classes.ShareIconStyle2}>
                                    <img src={socialIcons[iconName as keyof typeof socialIcons]} />
                                </IconButton>)
                            })}
                        </Box>
                        {jobOpeningsListPath === "/job-openings-list" && <Box className={classes.IconStyle3}>
                            <Chip className={classes.tagStyle} label={attributes?.resource_tag_id} />
                        </Box>}
                    </Box>
                </Grid>
                <hr style={{ color: "#000000", opacity: '20%', margin: "0px", }}></hr>
                <Grid 
                    container 
                    justifyContent='space-between'
                    style={{ padding: "65px 0" }}
                    spacing={2}
                >
                    <Grid item md={6} xs={12}>
                        {attributes?.prev_item && (
                            <Box 
                                style={{ cursor: "pointer" }} 
                                onClick={() => viewDetails(attributes.prev_item.id, "/events", true)}
                            >
                                <Box className={classes.arrowContianer}>
                                    <ChevronLeftIcon />
                                    Previous
                                </Box>
                                <Typography 
                                    className={classes.nextPrevBlogTitle}
                                >
                                    {attributes.prev_item.item}
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                        {attributes?.next_item && (
                            <Box
                                style={{ cursor: "pointer" }} 
                                onClick={() => viewDetails(attributes.next_item.id, "/events", true)}
                            >
                                <Box justifyContent="flex-end" className={classes.arrowContianer}>
                                    Next
                                    <ChevronRightIcon />
                                </Box>
                                <Typography 
                                    className={classes.nextPrevBlogTitle}
                                    style={{ textAlign: "right" }}
                                >
                                    {attributes.next_item.item}
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>
                <hr style={{ color: "#000000", opacity: '20%', margin: "0px", }}></hr>
            </Container>
            <RelatedPosts
                containerPage="events"
                heading="recent new articles"
                subHeading="The Latest Innovations in Bytewave"
                data={attributes?.other_resources.event_items.data}
                viewDetails={viewDetails}
            />
        </Box>
    )
}

export default EventBlogDetails;