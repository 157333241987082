import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Container, Box, Button, Chip} from "@material-ui/core";
import parse from 'html-react-parser';
import {removeTags} from '../../../blocks/DynamicContent/src/landingpage/LpDifferentFromOther';
import {IMAGE_HEIGHT, useStyles, HeadingText, socialIcons, getSocialLink} from './BlogDetails.web';
import {makeStyles} from "@material-ui/core/styles";
import {MdArrowOutward} from "react-icons/md";
import RelatedPosts from "./RelatedPosts.web";

interface PropTypes {
  heading?: string;
  enableButtons?: boolean;
  postType?: string,
  data?: any,
  navigate: () => void,
  viewDetails: any
}

const createStyles = makeStyles({
  buttonBlock: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "space-between"
  },
  button: {
    display: "inline-flex",
    alignItems: "center",
    gap: "6px",
    color: "#101010",
    padding: "12px 32px",
    borderRadius: "8px",
    fontFamily: "Lato",
    fontSize: "15px",
    fontWeight: 500,
    textTransform: "uppercase",
    cursor: "pointer"
  },
  button1: {
    backgroundColor: "#43d270",
  },
  button2: {
    backgroundColor: "#fff",
    border: "1px solid #000",
  }
})

const MarketplaceBlogDetails = ({enableButtons, postType, data, navigate, viewDetails}: PropTypes) => {
  const classes = useStyles({enableButtons, postType});
  const styles = createStyles()
  const attributes = data?.attributes;

  const fullPath = window.location.pathname;
  const pathParts = fullPath.split("/");
  const jobOpeningsListPath = `/${pathParts[1]}`;
  const tagCompanyAuthorInfo = [];
  attributes?.resource_tag_id && tagCompanyAuthorInfo.push(attributes.resource_tag_id);
  attributes?.compay && tagCompanyAuthorInfo.push(attributes.compay);
  attributes?.author && tagCompanyAuthorInfo.push(`By ${attributes.author}`);

  return (
    <Box>
      <Box className={classes.topGradiant}></Box>
      <Container>
        <Grid className={classes.firstGrid} style={{paddingTop: "60px"}}>
          <Container className={classes.miniContainer}>
            {attributes?.marketplace_title &&
                <Box className={classes.HeadingStyle}>
                    <HeadingText>{removeTags(attributes?.marketplace_title)}</HeadingText>
                </Box>
            }

          </Container>

          {attributes?.marketplace_description &&
              <Box>
                {jobOpeningsListPath !== "/job-openings-list" && <Box className={classes.ImageStyle}>
                  {
                    attributes?.marketplace_image &&
                      <Box className={classes.imageContainer}>
                          <img
                              src={attributes?.marketplace_image}
                              alt="blog image"
                              className={classes.ImageStyle1}
                          />
                      </Box>
                  }
                    <Box
                        className={classes.paragraphStyle}
                        style={{minHeight: attributes?.marketplace_image ? IMAGE_HEIGHT : "unset",}}
                    >
                      {parse(attributes?.marketplace_description)}
                    </Box>
                </Box>}
              </Box>
          }

          <div className={styles.buttonBlock}>
            <div className={`${styles.button} ${styles.button1}`} onClick={navigate}>Contact
              <MdArrowOutward/>
            </div>
            <div className={`${styles.button} ${styles.button2}`}
                 onClick={() => window.open(attributes?.marketplace_link)}>Visit Website
              <MdArrowOutward/>
            </div>
          </div>

          <RelatedPosts
            heading="You may also like"
            data={attributes?.other_resources.marketplace_items.data}
            containerPage={"marketplace"}
            viewDetails={viewDetails}
          />
        </Grid>
      </Container>
    </Box>
  )
}

export default MarketplaceBlogDetails;