import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { styled } from "@material-ui/core";
import clsx from "clsx";
import { downSelect } from '../assets'

const useStyles = makeStyles((theme) => ({
    allpaginationItems: {
        '& li:first-child': {
            marginRight: "20px",
            maxWidth: '36px',
            '@media(max-width: 460px)': {
                marginRight: "10px",
                borderRadius: '5px !important'
            }
        },
        '& li:last-child': {
            marginLeft: "20px",
            '@media(max-width: 460px)': {
                marginLeft: "10px",
                borderRadius: '5px !important'
            }
        },
        '& button': {
            backgroundColor: 'transparent !important',
            borderRadius: "8px !important",
            height: "36px",
            width: "36px",
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
            fontFamily: "Lato",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "160%",
            letterSpacing: "0.2px",
            padding: "0px !important",
            minWidth: "0px !important",
            '@media(max-width: 460px)': {
                borderRadius: '5px !important',
                height: "25px",
                width: "25px",
            },
        },
    },
    paginationitemHover: {
        display: "flex",
        justifyContent: "center",
        backround: "#ffffff !important",
        color: "#7D7D7F",
        fontFamily: "Lato",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "160%",
        letterSpacing: "0.2px",
        "& *": {
            color: "#7D7D7F !important",
        },
        "& .Mui-selected": {
            borderColor: "#329E54 !important",
            color: "#329E54 !important",
            fontWeight: 700,
        },
        "@media (max-width: 600px)": {
            marginRight: "0px",
        },
    },
    dropContainer: {
        display: 'flex', alignItems: 'center', gap: "16px", color: "#34373A",
        fontFamily: "Lato",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "150%",
        letterSpacing: "0.2px",
        minWidth: '200px'
    },
    select: {
        border: "1px solid #D9D9D9",
        borderRadius: "8px",
        marginTop: 0,
        color: "#7D7D7F !important",
        font: 'inherit',
        height: '36px',
        padding: '8.5px 14px',
        background: '#FFFFFF',
        appearance: 'none',
        maxWidth: '104px',
        width: '100%',
        "& .MuiSelect-outlined.MuiSelect-outlined": {
            color: "#b1b3c3",
        },
        '& div:focus': {
            backgroundColor: "rgba(0, 0, 0, 0) !important",
        },
        '& *': {
            color: "#34373A !important",
        }
    },
    selectselected: {
        marginTop: 0,
        border: "1px solid #D9D9D9",
        borderRadius: "8px",
        color: "#7D7D7F !important",
        font: 'inherit',
        background: "#fff !important",
        fontFamily: "Lato",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "160%",
        minWidth: '104px',
        maxWidth: '104px',
        height: '36px',
        padding: '8px 16px',
        maxHeight: '36px',
        gap: "16px"
    },
    icon: {
        backgroundImage: `url(${downSelect}) !important`,
        backgroundRepeat: "no-repeat !important",
        backgroundPosition: "top 11px right 16px !important",
        appearance: "none",
        color: "#fff",
        cursor: "pointer",
    },
}));

interface TablePaginationProps {
    count: number;
    variant?: "text" | "outlined";
    shape?: "round" | "rounded";
    color?: "primary" | "secondary";
    size?: "small" | "medium" | "large";
    boundaryCount?: number;
    showFirstButton?: boolean;
    showLastButton?: boolean;
    onChange?: any;
    handleChangeNumber?: any;
    perPage?: number;
}

const PaginationContainer = styled('div')({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
    alignItems: 'center',
    '@media(max-width: 674px)': {
        flexDirection: 'column',
        height: "100px",
        padding: 0
    },
});

const TablePagination: React.FC<TablePaginationProps> = ({
    count,
    variant = "outlined",
    shape = "rounded",
    size = "large",
    boundaryCount = 1,
    showFirstButton = false,
    showLastButton = false,
    onChange,
    handleChangeNumber,
    perPage = 5,
}) => {
    const classes = useStyles();
    const pageCounts = count > 0 ? Math.ceil(count / perPage) : 0;

    return (
        <PaginationContainer>
            <div className={classes.dropContainer}>
                Show rows:
                <select
                    defaultValue={5}
                    value={perPage}
                    className={perPage ? clsx(classes.selectselected, classes.icon) : clsx(classes.select, classes.icon)}
                    onChange={handleChangeNumber} >
                    {
                        [5, 10, 15].map((item) => (
                            <option value={item}>{`${item} items`}</option>
                        ))
                    }
                </select>
            </div>
            <div className={classes.paginationitemHover}>
                {pageCounts > 0 &&
                    <Pagination
                        onChange={onChange}
                        count={pageCounts}
                        variant={variant}
                        shape={shape}
                        size={size}
                        boundaryCount={boundaryCount}
                        showFirstButton={showFirstButton}
                        showLastButton={showLastButton}
                        classes={{ ul: classes.allpaginationItems }}
                    />
                }
            </div>
        </PaginationContainer>
    );
};

export default TablePagination;
