import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getLastMonthCurrentYear } from "./common/MonthYearSelect.web";
import { getMonthName } from "./common/CalenderSelectField.web";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { apiCall } from "../../cfnzdpuassuranceandverification/src/CfnzdpuassuranceandverificationController";
const crypto = require('crypto');

interface TooltipContext {
  series: number[][];
  seriesIndex: number;
  dataPointIndex: number;
  w: {
    globals: {
      labels: string[];
    };
  };
}

interface FormErrors {
  currentPass: string,
  newPass: string,
  confirmNewPass: string,
  [key: string]: string
}

interface NavigationPayload {
  taskId: number;
  materialFactorId: number | null;
  categoryId: number;
  coreFactorId: number;
  cardId: number;
  status: string;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  onAssign?: any;
  onClose?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  accountDetails: Record<string,Record<string,string>>;
  dashboardData: any;
  locationData: any,
  additionalQuestions: any,
  selectedLocation: string,
  cardEndPoint: string,
  calculatedData: any,
  consumptionValue: string,
  consumptErr: boolean,
  token: string;
  errorMsg: string;
  loading: boolean;
  error: boolean,
  resources: any;
  errorMessage: string;
  selectedCountry: string;
  countryCode: any;
  onchangeState: boolean;
  phoneNumber: string;
  isShowWelcome: boolean;
  isShowResources: boolean;
  companyData: any,
  showPass1: boolean,
  showPass2: boolean,
  showPass3: boolean,
  employeesData: any,
  personalData: any,
  btnColor: any,
  status: boolean,
  paswordStatus: boolean,
  passwordData: any,
  profileImage: any,
  profileImageURL: any;
  modelProp: any;
  selectedItem: any[],
  selectedItemScopeName: any[],
  selectedScopeLabel: any[];
  selectedScopeItem: any[],
  errModal: boolean,
  seriesVisibility2: any,
  updatedDashedArr: any[],
  switch1: boolean,
  itemId: any,
  switch2: boolean,
  switch3: boolean,
  switch4: boolean,
  tasks: any;
  profileData: any;
  editInfoModal: boolean,
  anchorEl: any;
  memberList: any;
  isShowMember: boolean;
  taskId: number;
  planData: any;
  graphData: any;
  getProfileResponse: any;
  changePasswordResponse: any;
  updateProfileResponse: any;
  companyInfoResponse: any,
  companyInfo: any;
  updatedResponse: any,
  isAssignTask: boolean;
  AccountTableData: any;
  userType: string;
  esgFactorData: any;
  esgFactorPageNum: number;
  esgFactorDetails: any;
  esgFactorDetailsForm: any;
  esgFactorDetailsFormError: boolean;
  esgFactorDetailsFormResponse: boolean;
  esgFactorCardDeleteError: boolean;
  esgFactorCardArchiveError: boolean;
  esgFactorDeleteRes: any;
  esgFactorArchiveRes: any;
  esgFactorQuestionAnswers: any;
  perPage: number;
  page: number;
  totalUser: number;
  isRemove: HTMLElement | null;
  removeId: number;
  isEsgDone: boolean;
  cardId: number;
  openSelectbox: boolean,
  esgCardDelete: any;
  esgCardArchive: any;
  esgCardUnArchive: {
    openConfirmation: boolean,
    cardId: string,
    success: boolean;
  };
  showDeleteAlert: boolean,
  deleteUpdateStatus: string,
  showArchiveAlert: boolean,
  archiveUpdateStatus: string,
  cardFilterDate: string,
  alert: {
    show: boolean,
    message: string,
    status: any
  },
  carbonFail: {
    show: boolean,
    message: string,
  },
  averageEmission: number,
  isShowGraphs: any,
  allgraphData: any,
  xGraphData: any,
  emmisionyear: number,
  orgreportmonth: string,
  descreportmonth: string,
  envyear: number,
  isShowAllGraphs: boolean
  companylogo: string
  isCardAction: boolean,
  formError: boolean,
  errorGraphMessage:string
  openOverAllEmission:boolean
  openOrganization:boolean;
  opendescreport:boolean;
  openEnvironment:boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetPlanDataCallId: string = "";
  
  apiGetGraphDataCallId: string = "";
  apiGetProfileDataCallId: string = "";
  changePasswordCallId: string = "";
  apiUpdateProfileDataCallId: string = "";
  companyInfoCallId: string = "";
  updateCompanyInfoCallId: string = "";
  apiGetQueryStrinurl: string = "";
  memberApiCallId: string = "";
  DeletememberApiCallId: string = "";
  assignmemberApiCallId: string = "";
  assignCardApiCallId: string = "";
  calculateApiCallId: string = "";
  statusApiCallId: string = "";
  account_id: number;
  account_name: string;
  apiAccountDataCallId: string = "";
  apiEsgFactortDataCallId: string = "";
  apiEsgFactorDetailsCallId: string = "";
  apiEsgFactorSaveCallId: string = "";
  apiEsgFactorDeleteCallId: string = "";
  apiEsgFactorArchiveCallId: string = "";
  apiEsgFactorUnArchiveCallId: string = "";
  emmissionApiCallId: string = "";
  orgreportApiCallId: string = "";
  dscreportApiCallId: string = "";
  envreportApiCallId: string = "";
  OverallDataApiCallId: string = "";
  account_type: string = "";
  organizationid: number = 0;
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionResponseData)
    ];

    this.state = {
      accountDetails: {},
      dashboardData: [],
      locationData: [],
      additionalQuestions: [],
      selectedLocation: "",
      cardEndPoint: "",
      profileData: { first_name: "", last_name: "", full_name: "", email: "", photo: "", phone_no: "", user_type: "", department: "" },
      calculatedData: {},
      consumptionValue: "",
      consumptErr: false,
      error: true,
      errorMsg: "",
      errModal: false,
      token: "",
      loading: false,
      selectedCountry: "sg",
      countryCode: "",
      onchangeState: false,
      phoneNumber: "",
      errorMessage: "",
      resources: [],
      tasks: [],
      editInfoModal: false,
      isShowWelcome: true,
      isShowResources: true,
      modelProp: {
        open: false
      },
      showPass1: false,
      showPass2: false,
      showPass3: false,
      companyData: {
        name: "",
        website: "",
        numberOfEmployees: "",
        address: "",
        logo: "",
        phoneNo: ""
      },
      employeesData: [],
      btnColor: false,
      status: false,
      paswordStatus: false,
      personalData: {
        firstName: "",
        lastName: "",
        contactNo: "",
      },
      passwordData: {
        currentPass: "",
        newPass: "",
        confirmNewPass: ""
      },
      profileImage: {},
      profileImageURL: "",
      selectedScopeLabel: [],
      selectedItem: [],
      selectedItemScopeName: [],
      selectedScopeItem: [
        { id: 0, name: 'Scope 1' },
        { id: 1, name: 'Scope 2' },
        { id: 2, name: 'Scope 3' },
      ],
      seriesVisibility2: [true, false, false, false],
      updatedDashedArr: [0, 0, 5, 0],
      itemId: '',
      switch1: true,
      switch3: false,
      switch2: false,
      switch4: false,
      anchorEl: false,
      memberList: [],
      isShowMember: false,
      taskId: 0,
      planData: {},
      graphData: {},
      getProfileResponse: {},
      changePasswordResponse: {},
      updateProfileResponse: {},
      companyInfoResponse: {},
      companyInfo: {
        company_name: "", company_website: "", company_registered_address: "", number_of_employees: "", no_of_employees_text: "",
        company_logo: "", main_contact: "", main_contact_email: "", phone_no: ""
      },
      updatedResponse: {},
      isAssignTask: false,
      AccountTableData: [],
      userType: '',
      esgFactorData: {},
      esgFactorPageNum: 1,
      esgFactorDetails: {},
      esgFactorDetailsForm: {
        location: "",
        timePeriod: ""
      },
      esgFactorDetailsFormError: false,
      esgFactorDetailsFormResponse: false,
      esgFactorCardDeleteError: false,
      esgFactorCardArchiveError: false,
      esgFactorDeleteRes: {},
      esgFactorArchiveRes: {},
      esgFactorQuestionAnswers: [],
      perPage: 5,
      page: 1,
      totalUser: 0,
      isRemove: null,
      removeId: 0,
      isEsgDone: false,
      cardId: 0,
      openSelectbox: false,
      esgCardDelete: {},
      esgCardArchive: {},
      showDeleteAlert: false,
      deleteUpdateStatus: "",
      showArchiveAlert: false,
      archiveUpdateStatus: "",
      cardFilterDate: "",
      alert: {
        show: false,
        message: '',
        status: ''
      },
      carbonFail: {
        show: false,
        message: '',
      },
      averageEmission: 0,
      isShowGraphs: {
        emmision: false,
        orgreport: false,
        descreport: false,
        envreport: false
      },
      allgraphData: {
        emmision: [],
        orgreport: [],
        descreport: [],
        envreport: []
      },
      xGraphData: {
        emmision: [],
        orgreport: [],
        descreport: [],
        envreport: []
      },
      emmisionyear: new Date().getFullYear(),
      orgreportmonth: `${getMonthName().month}-${getMonthName().year}`,
      descreportmonth: `${getMonthName().month}-${getMonthName().year}`,
      envyear: new Date().getFullYear(),
      isShowAllGraphs: false,
      companylogo: '',
      isCardAction: false,
      esgCardUnArchive: {
        openConfirmation: false,
        cardId: "",
        success: false,
      },
      formError: false,
      errorGraphMessage:"",
      openOverAllEmission:false,
      openOrganization:false,
      opendescreport:false,
      openEnvironment:false
    };

    this.account_id = JSON.parse(localStorage.getItem("userDetails") as string)?.meta?.id
    this.account_name = JSON.parse(localStorage.getItem("userDetails") as string)?.meta?.first_name
    this.account_type = JSON.parse(localStorage.getItem("userDetails") as string)?.meta?.user_account_type
    this.organizationid = JSON.parse(localStorage.getItem('organization') as string)?.id
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getPlanData();
    this.getTableData()
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleReceveFunction(apiRequestCallId, responseJson)
      this.handleReceveFunction2(apiRequestCallId, responseJson)
    }

    // Customizable Area End
  }

  // Customizable Area Start

  handleReceveFunction(apiRequestCallId: any, responseJson: any) {
    switch (apiRequestCallId) {
      case this.dashboardApiCallId:
        if (!responseJson.errors) {
          const not_completed = responseJson.tasks.filter((item: any) => !item.completed)
          this.setState({ resources: responseJson.resources })
          this.setState({ tasks: not_completed })
          this.setState({ isAssignTask: responseJson.can_assign_task })
          this.setState({ userType: responseJson.user_account_type })

          // It is used on internal pages
          localStorage.setItem('tasks', JSON.stringify(responseJson.tasks));
        }
        break;
      case this.memberApiCallId:
        if (!responseJson.errors) {
          this.setState({ memberList: responseJson.team_members })
        }
        break;
      case this.assignmemberApiCallId:
        if (responseJson.success) {
          this.getTasks()
        }
        break;
      case this.apiGetPlanDataCallId:
        this.setState({ planData: responseJson })
        break;
      case this.apiGetGraphDataCallId:
        this.setState({ graphData: responseJson, errModal: responseJson?.error ? true : false })
        break;
      case this.apiAccountDataCallId:
        this.setState({
          AccountTableData: responseJson.accounts,
          totalUser: responseJson.total_users
        })
        break;
      case this.statusApiCallId:
        let error = 'Something wen wrong please try again'
        this.commonCallFunction(responseJson, error)
        break;
      case this.apiEsgFactortDataCallId:
        this.setState({ esgFactorData: responseJson, isCardAction: responseJson.can_delete_archive_card })
        break;
      case this.apiEsgFactorSaveCallId:
        if (responseJson.success) {
          this.fetchEsgFactorDetails();
          this.setState({ esgFactorDetailsFormResponse: responseJson.success })
        }
        break;
      case this.calculateApiCallId:
        this.carbonCallFunction(responseJson)
        break;

      case this.apiEsgFactorDeleteCallId:
        this.setState({ esgFactorDeleteRes: responseJson })
        const cat = this.props.navigation?.getParam('category');
        const month = this.state.cardFilterDate;
        this.fetchEsgFactorData(cat, this.state.esgFactorPageNum, month)
        break;
      case this.apiEsgFactorArchiveCallId:
        this.setState({ esgFactorArchiveRes: responseJson })
        const cat1 = this.props.navigation?.getParam('category');
        const month2 = this.state.cardFilterDate;
        this.fetchEsgFactorData(cat1, this.state.esgFactorPageNum, month2)
        break;
      case this.DeletememberApiCallId:
        error = responseJson.error
        this.commonCallFunction(responseJson, error)
        break;
      case this.assignCardApiCallId:
        if (responseJson.success) {
          const cat1 = this.props.navigation?.getParam('category');
          const month2 = this.state.cardFilterDate;
          this.fetchEsgFactorData(cat1, this.state.esgFactorPageNum, month2)
        }
        break;
      case this.emmissionApiCallId:
        this.setGraphData(responseJson, "emmition")
        break;
      case this.orgreportApiCallId:
        this.setGraphData(responseJson, "orgreport")
        break;
      case this.dscreportApiCallId:
        this.setGraphData(responseJson, "dscreport")
        break;
      case this.envreportApiCallId:
        this.setGraphData(responseJson, "envreport")
        break;
      case this.OverallDataApiCallId:
        this.setState({ isShowAllGraphs: responseJson.success })
        break;
    }

  }

  handleReceveFunction2 = (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.apiGetGraphDataCallId:
        this.setState({ graphData: responseJson, errModal: responseJson?.error ? true : false })
        break;
      case this.apiGetProfileDataCallId:
        this.setState({ getProfileResponse: responseJson, countryCode: responseJson?.data?.profile_info.country_code, profileData: responseJson?.data?.profile_info || {}, profileImageURL: responseJson.data?.profile_info.photo })
        let responseData = responseJson.data?.profile_info
        let { first_name, last_name, designation, phone_no } = responseData
        this.setState({ phoneNumber: responseData?.phone_no })
        let filteredData = responseJson?.data?.designations.filter((item: any) => item.designation_name === designation) || ""
        let data = this.state.personalData
        data.firstName = first_name
        data.lastName = last_name
        data.contactNo = `${responseJson?.data?.profile_info.country_code}${phone_no}`

        this.handleLocalStorage(responseJson?.data)
        break;
      case this.changePasswordCallId:
        this.handleStates(responseJson)
        break;
      case this.apiUpdateProfileDataCallId:
        this.setState({ updateProfileResponse: responseJson, editInfoModal: false, status: responseJson.success, btnColor: false, onchangeState: false })
        if (responseJson.success) {
          this.getProfileData()
        }
        break;
      case this.companyInfoCallId:
        this.setState({ companyInfoResponse: responseJson, countryCode: responseJson?.data?.company_data?.country_code, employeesData: responseJson.data.number_of_employee_data, companyInfo: responseJson.data.company_data })
        let data2 = this.state.companyData
        let response = responseJson.data.company_data
        this.setState({ phoneNumber: response?.phone_no })
        this.setState({ companylogo: response.company_logo })
        let filteredDataCompany = responseJson.data?.number_of_employee_data?.filter((item: any) => item.id == response.number_of_employees)
        data2.name = response?.company_name
        data2.website = response?.company_website
        data2.numberOfEmployees = (filteredDataCompany?.length > 0) ? filteredDataCompany[0]?.id : ""
        data2.address = response?.company_registered_address
        data2.logo = response?.company_logo
        data2.phoneNo = `${responseJson?.data?.company_data?.country_code} ${response?.phone_no}`
        break;
      case this.updateCompanyInfoCallId:
        this.setState({ updatedResponse: responseJson, editInfoModal: false, status: responseJson.success, onchangeState: false })
        if (responseJson.success) {
          this.getCompanyInfoDataApi()
        }
        break;
      case this.apiEsgFactorDetailsCallId:
        if (responseJson.success) {
          this.setState({
            loading: false,
            esgFactorDetails: responseJson.data,
            locationData: responseJson.data?.questions?.[0]?.api_datas?.api_params_data[1]?.param_options,
            additionalQuestions: responseJson.data?.questions?.[0]?.api_datas?.api_params_data[0],
            cardEndPoint: responseJson.data?.questions?.[0]?.api_datas?.api_end_point,
            consumptionValue: responseJson.data?.general_answer_data === null ? "" : responseJson.data?.general_answer_data?.api_access_data?.consumption,
          })
        }
        break;
      case this.apiEsgFactorUnArchiveCallId:
        this.handleUnArchiveEsgFactorCardResponse(responseJson);
        break;
    }

  }

  handleStates = (responseJson: any) => {
    if (responseJson.success) {
      this.setState({ changePasswordResponse: responseJson, paswordStatus: true, status: true, error: true })
    } else {
      this.setState({ changePasswordResponse: responseJson, error: true, errorMessage: responseJson?.errors?.account })
    }
  }

  setGraphData(responseJson: any, type: string) {
    switch (type) {
      case "emmition":
        if (responseJson.success) {
          this.setState({
            averageEmission: responseJson.average_emission,
            isShowGraphs: {
              ...this.state.isShowGraphs, emmision: true
            },
            allgraphData: {
              ...this.state.allgraphData, emmision: responseJson.overall_emissions
            },
            xGraphData: {
              ...this.state.xGraphData, emmision: responseJson.x_axis
            }
          })
        } else {
          this.setState({
            isShowGraphs: {
              ...this.state.isShowGraphs, emmision: false
            },
            errorGraphMessage:"Please update your emissions data for any 3 consecutive months to generate the emissions graph"
          })
        }
        break;
      case "orgreport":
        if (responseJson.success) {
          this.setState({
            isShowGraphs: {
              ...this.state.isShowGraphs, orgreport: true
            },
            allgraphData: {
              ...this.state.allgraphData, orgreport: responseJson.percentage
            },
            xGraphData: {
              ...this.state.xGraphData, orgreport: responseJson.groups
            }
          })
        } else {
          this.setState({
            isShowGraphs: {
              ...this.state.isShowGraphs, orgreport: false
            }
          })
        }
        break;
      case "dscreport":
        if (responseJson.success) {
          this.setState({
            isShowGraphs: {
              ...this.state.isShowGraphs, descreport: true
            },
            allgraphData: {
              ...this.state.allgraphData, descreport: responseJson.percentage
            },
            xGraphData: {
              ...this.state.xGraphData, descreport: responseJson.x_axis_values
            }
          })
        } else {
          this.setState({
            isShowGraphs: {
              ...this.state.isShowGraphs, descreport: false
            }
          })
        }
        break;
      case "envreport":
        if (responseJson.success) {
          this.setState({
            isShowGraphs: {
              ...this.state.isShowGraphs, envreport: true
            },
            allgraphData: {
              ...this.state.allgraphData, envreport: responseJson.points
            },
            xGraphData: {
              ...this.state.xGraphData, envreport: responseJson.x_axis_values
            }
          })
        } else {
          this.setState({
            isShowGraphs: {
              ...this.state.isShowGraphs, envreport: false
            }
          })
        }
        break;
    }
  }

  carbonCallFunction(responseJson: any) {
    if (responseJson.data.success) {
      this.setState({ calculatedData: responseJson.data })
      let factorQuestions = this.state.esgFactorDetails.questions || []
      let e = { target: { value: responseJson.data.carbon.replace(" kg co2", "") } }
      factorQuestions.map((qitem: any) => {
        this.esgFactorsQuestionChange(e, qitem.id, 'text')
      })
    } else {
      this.setState({
        carbonFail: {
          show: true,
          message: responseJson.data.message,
        }
      })
    }
  }

  commonCallFunction(responseJson: any, error: string) {
    if (responseJson.success) {
      this.getTableData()
      this.setState({
        alert: {
          show: true,
          message: responseJson.message,
          status: 'success'
        }
      })
    } else {
      this.setState({
        alert: {
          show: true,
          message: error,
          status: 'error'
        }
      })
    }
  }

  navigateOnCard = () => {
    this.props.navigation.navigate("LogEsgFactors", {
      category: "core"
    })
  }

  coreFactorIdRenderName = (coreFactId: any) => {
    if (coreFactId) {
      return 'Core'
    }
    return "";
  }

  isLogin() {
    const isLogin = JSON.parse(localStorage.getItem("isLogin") as string)
    if (!isLogin) {
      this.props.navigation.navigate("Login", { token: 'primary' })
    }
  }

  closeWelcome() {
    this.setState({ isShowWelcome: false })
  }

  closeResources() {
    this.setState({ isShowResources: false })
  }


  getTasks() {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };

    let httpBody = {
      "account_id": this.account_id,
    } as { account_id: number; company_id?: number };

    if (this.account_type === "portfolio_manager") {
      httpBody.company_id = this.organizationid
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.dashboardApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardDataUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getMembers() {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };

    let httpBody = {
      "account_id": this.account_id,
    } as { account_id: number; company_id?: number };

    if (this.account_type === "portfolio_manager") {
      httpBody.company_id = this.organizationid
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.memberApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.membersDataUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  ShowVideo(type: string, url: string) {
    if (type === "video") {
      this.setState({ modelProp: { open: true, link: url } })
    } else {
      this.setState({ modelProp: { open: false, link: "" } })
    }
  }

  handleClose = () => {
    this.setState({
      modelProp: { open: false, link: "" },
      esgFactorDetailsFormResponse: false, carbonFail: { show: false, message: "" }
    })
  }


  handleCheckbox = (e: any) => {
    const { value } = e.target
    this.setState({ selectedItem: value })
  }

  renderSelectedValue = (selected: any[]) => {
    if (selected.length > 0) {
      return selected.join(' & Other')

    } else {
      return [' Select Scope']
    }

  }

  selectLabelScope = (id: any, name: any) => {
    if (this.state.selectedItemScopeName.includes(id)) {
      this.setState(prevState => ({
        selectedItemScopeName: prevState.selectedItemScopeName.filter((scopeId: any) => scopeId !== id),
        selectedScopeLabel: prevState.selectedScopeLabel.filter(label => label !== name)
      }))
    } else {
      this.setState(prevState => ({
        selectedItemScopeName: [...prevState.selectedItemScopeName, id],
        selectedScopeLabel: [...prevState.selectedScopeLabel, name],
      }))
    }
  }

  handleSwitches = (index: number, itemId: number) => {
    this.handleClicks(itemId)
    let dashArray = [0, 0, 5, 0].filter((_, index) => { return this.state.seriesVisibility2[index] === true })
    this.setState({ updatedDashedArr: dashArray })
  }

  handleLocalStorage = (responseJson: any) => {
    const userDetailsString = localStorage.getItem("userDetails");
    const userDetails = userDetailsString ? JSON.parse(userDetailsString).meta : {};
    const firstName = responseJson?.profile_info?.first_name;
    const lastName = responseJson?.profile_info?.last_name
    if (userDetails?.photo !== responseJson?.profile_info?.photo  || (userDetails?.first_name !== firstName) 
    || (userDetails?.last_name !== lastName)
    ) {
      const updatedUserDetails = { ...userDetails, photo: responseJson?.profile_info?.photo, first_name: firstName,last_name: lastName };
      localStorage.setItem('userDetails', JSON.stringify({ meta: updatedUserDetails }));
      this.props.navigation?.navigate("ProfilePage", {
        orgTab: "profile"
      })
      window.location.reload()
    }
  }

  handleClicks = (itemId: any) => {
    const { switch1, switch3, switch2, switch4, seriesVisibility2 } = this.state
    let key = `switch${itemId}`
    switch (key) {
      case "switch1":
        this.setState({ switch1: !switch1, itemId })
        seriesVisibility2[itemId - 1] = !switch1
        break;
      case "switch2":
        this.setState({ switch2: !switch2, itemId })
        seriesVisibility2[itemId - 1] = !switch2
        break;
      case "switch3":
        this.setState({ switch3: !switch3, itemId })
        seriesVisibility2[itemId - 1] = !switch3
        break;
      case "switch4":
        this.setState({ switch4: !switch4, itemId })
        seriesVisibility2[itemId - 1] = !switch4
        break;
    }
  }
  getColors(status: string) {
    switch (status) {
      case "Ongoing":
        return "#7CB305"
      case "Pending":
        return "#FA8C16"
      case "Completed":
        return "#7CB305"
    }
  }

  getDaysLeft(days: number) {
    if (days <= 2) {
      return { background: "#FFF1F0", text: '#5C0011' }
    }
    if (days >= 3 && days <= 6) {
      return { background: "#FFF7E6", text: '#D46B08' }
    }
    if (days >= 7) {
      return { background: "#ECFBF1", text: '#43D270' }

    }
  }

  handleOpenMenu = (id: number) => {
    this.setState({
      anchorEl: true, taskId: id
    })
  }

  handleCloseMenu = () => {
    this.setState({
      anchorEl: false, taskId: 0
    })
  }

  handleChangeTeamMember = (id: number) => {


    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };

    const httpBody = {
      account_id: id,
      "task_id": this.state.taskId,
      "task_url": `${window.location.host}/get-started`
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.assignmemberApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.assignTaskUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assignApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleShowMember = () => {
    this.setState({
      isShowMember: true, anchorEl: null
    })
  }


  handleCloseMember = () => {
    this.setState({
      isShowMember: false
    })
  }


  getPlanData() {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPlanDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPlanDataEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.dashboarContentType,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        "account_id": this.account_id
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getGraphData() {

    let httpBody = {
      "account_id": this.account_id,
    } as { company_id?: number };

    if (this.account_type === "portfolio_manager") {
      httpBody.company_id = this.organizationid
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetGraphDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.graphDataEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.dashboarContentType,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.archiveEsgFactorCardApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getProfileData = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProfileDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.dashboarContentType,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        "account_id": this.account_id
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.archiveEsgFactorCardApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  changePasswordApi = (values: any) => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.dashboarContentType,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        "account_id": this.account_id,
        "new_password": values?.confirmNewPass,
        "current_password": values?.currentPass
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.archiveEsgFactorCardApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    values.confirmNewPass = ""
    values.currentPass = ""
    values.newPass = ""
  }

  updateProfileData = () => {
    let { firstName, lastName, contactNo } = this.state.personalData;
    let { onchangeState, countryCode, phoneNumber } = this.state
    let data = contactNo?.replace(`+${this.state.countryCode}`, "")
    const regex = /[^0-9]/g;
    if(firstName === "" || lastName === ""){
      this.setState({
        formError:true
      })
    }else{
      this.setState({
        formError:false
      })
      const formData = new FormData();
    formData.append('data[first_name]', firstName);
    formData.append('data[last_name]', lastName);
    formData.append('data[department_id]', "1");
    formData.append('data[phone_number]', onchangeState ? data.replace(regex, "") : phoneNumber);
    formData.append('data[country_code]', countryCode);
    formData.append('data[account_id]', this.account_id?.toString());
    if (this.state.profileImage?.name && typeof (this.state.profileImage) !== 'string') {
      formData.append('data[photo]', this.state.profileImage);
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateProfileDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      {}
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assignApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    
  }

  getTableData = () => {
    const { page, perPage } = this.state

    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };
    
    const task_id = this.getTaskId();
    
    let httpBody = {
      data: {
        account_id: this.account_id,
        page_no: page,
        items_per_page: perPage,
        task_id: task_id,
      } as { company_id?: number }
    };

    if (this.account_type === "portfolio_manager") {
      httpBody.data.company_id = this.organizationid
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAccountDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAccountDataEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getButtonProperties(isAssigned: boolean) {
    if (isAssigned && this.state.isAssignTask) {
      return { color: "#FFFFFF", background: "#34373A", title: 'Assign Task' }
    } else {
      return { color: "#34373A", background: "#43D270", title: 'Start Task' }
    }
  }
  handleTask(item:{
    task_code: string, 
    card_id: number, 
    id: number, 
    material_factor_id: number, 
    category_id: number, 
    core_factor_id: number, 
    is_assign: boolean,
    title:string}) {
    
      sessionStorage.setItem("taskId", item.id.toString())
    
    if (item.is_assign && this.state.isAssignTask) {
      this.setState({
        isShowMember: true, taskId: item.id
      })
    } else {
      this.switchCaseNavigation(item.task_code, item.card_id, item.id, item.material_factor_id, item.category_id, item.core_factor_id,item.title)
    }
  }

  switchCaseNavigation = (index: string, card_id: number, id: number, material_factor: number, category_id: number, core_factor_id: number,title:string) => {
    switch (index) {
      case "task_1":
        this.props.navigation.navigate("MapOrganization", {
          orgTab: "organization-info"
        })
        break;
      case "task_2":
        // Doing this to make sure type is "core" while fetching the ESG data using fetchEsgFactorData
        setStorageData("activeSidebarLink", "/measure/log-your-esg-factors/core");
        this.props.navigation.navigate("LogEsgFactors", {
          category: "core"
        })
        break;
      case "task_3":
        this.props.navigation.navigate("MapOrganization", {
          orgTab: "users"
        })
        break;
      case "CARD":
        if(title === 'Energy Consumption'){
           let taskId = this.getTaskId();
      
      const energyConsumptionPayload = {
				taskId: taskId,
				materialFactorId: material_factor,
				categoryId: category_id,
				coreFactorId: core_factor_id,
				cardId: card_id,
        status: "published"
			};
			setStorageData("energyConsumptionPayload", JSON.stringify(energyConsumptionPayload));
			this.navigateToEnergyConsumptionPage();
        }else{
        this.props.navigation.navigate("EsgFactorsDetails", {
          id: id,
          mid: material_factor,
          cid: category_id,
          coreFacId: core_factor_id,
          card_id: card_id,
          status: "published"
        })
        }
        break;
    }
  }

  isShowPopup(id: number) {
    if (this.state.anchorEl && id === this.state.taskId) {
      return "flex"
    } else {
      return "none"
    }
  }

  isTaskAssign(isAssigned: boolean) {
    if (isAssigned && this.state.isAssignTask) {
      return { height: 78, position: 64 }
    } else {
      return { height: 54, position: 44 }
    }
  }

  getRedirectLinks = (
    index: string, 
    card_id: number, 
    id: number, 
    material_factor: number, 
    category_id: number, 
    core_factor_id: number,
    card_title: string,
  ) => {
    sessionStorage.setItem("taskId", id.toString());
    if (card_title === "Energy Consumption") {
      
      let taskId = this.getTaskId();
      
      const energyConsumptionPayload = {
				taskId: taskId,
				materialFactorId: material_factor,
				categoryId: category_id,
				coreFactorId: core_factor_id,
				cardId: card_id,
			};
			setStorageData("energyConsumptionPayload", JSON.stringify(energyConsumptionPayload));
			this.navigateToEnergyConsumptionPage();
      return;
    }
    this.switchCaseNavigation(index, card_id, id, material_factor, category_id, core_factor_id,card_title)
  }

  handleChange = (index: number, itemId: number) => {
    const switches = this.state.AccountTableData?.map((row: any) => row.status)
    const newSwitches = [...switches];
    newSwitches[index] = !newSwitches[index];
    this.handleUserAccount(itemId, newSwitches[index])
  };

  handleUserAccount(itemId: number, newStatus: boolean) {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };

    const httpBody = {
      "data": {
        "account_id": itemId,
        "status": newStatus
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.statusApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.handleAccountEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setCardFilterDate(date: string) {
    setStorageData("cardMonth", date)
    this.setState({ cardFilterDate: date })
  }

  async fetchEsgFactorData(cat: string, page: number, cardMonth?: string) {

    const cardStatus = ['Completed', 'Archived', 'Recommended']

    const cardMonthData = await getStorageData("cardMonth")

    if (!cardMonth && !cardMonthData) {
      cardMonth = `${getMonthName().month}-${getMonthName().year}`;
      this.setCardFilterDate(cardMonth)
    } else {
      cardMonth = cardMonthData
      this.setCardFilterDate(cardMonthData)
    }

    let catName = "";
    const taskid = this.getTaskId();

    if (cat && cat.length > 0) {
      catName = cat[0].toUpperCase();
    }

    if (cat && cat.length > 1) {
      catName += cat.slice(1)
    }

    const reqMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEsgFactortDataCallId = reqMsg.messageId;

    reqMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEsgFactorDataEndPoint
    );

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.dashboarContentType,
      })
    );

    const reqCat = cardStatus.includes(catName) ? 'All' : catName;
    const reqStatus = cardStatus.includes(catName) ? catName.toLowerCase() : 'published';
    const activeSidebarLink = await getStorageData("activeSidebarLink");
    const param = activeSidebarLink.split("/").at(-1);
    let type;
    if (param === "all") {
      type = "materiality"
    } else if (param === "core") {
      type = "core"
    }

    let httpBody = {
      "account_id": this.account_id,
      "category": reqCat,
      "page_no": page,
      "items_per_page": 9,
      "task_id": taskid,
      "card_year_month": cardMonth,
      "status": reqStatus,
      "type": type
    } as { company_id?: number };

    if (this.account_type === "portfolio_manager") {
      httpBody.company_id = this.organizationid
    }

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getEsgFactorApiMethod
    );

    runEngine.sendMessage(reqMsg.id, reqMsg);
  }

  handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, id: number) => {
    this.setState({
      anchorEl: event?.currentTarget,
      removeId: id
    });
  };

  handleCloseRemove = () => {
    this.setState({
      anchorEl: null,
      removeId: 0
    });
  };

  handleNavigate = () => {
    this.props.navigation.goBack()
  }

  handleRemoveUser = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DeletememberApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.DeleteUserEndPoint + "/" + `${this.state.removeId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.handleCloseRemove()
    this.updatePagination()
  }

  handleOnPaginationChange = (e: any, page: number) => {
    e.preventDefault();
    this.setState({ esgFactorPageNum: page })
    const cat = this.props.navigation?.getParam('category');
    const month = this.state.cardFilterDate;
    this.fetchEsgFactorData(cat, page, month)
  }

  handleOnEsgFactorTabChange = (cat: string) => {
    const month = this.state.cardFilterDate;
    this.fetchEsgFactorData(cat, 1, month)
  }

  getTaskId = () => {
    let taskId = "";
    const taskLocal = localStorage.getItem('tasks');
    if (taskLocal && taskLocal !== "") {
      const taskFound = JSON.parse(taskLocal).find((task: any) => task.task_code === "task_2")
      taskId = taskFound?.id;
    }
    return taskId
  }

  setEsgFactorForm = (value: any, name: string) => {
    this.setState({
      esgFactorDetailsForm: { ...this.state.esgFactorDetailsForm, [name]: value }
    })
  }

  esgFactorsFieldChange = (e: any, name: string, fieldType?: string) => {
    let { value } = e.target;
    this.setState({ selectedLocation: value })
    if (name == "activity") {
      value = value.substring(0, 250);
    }
    this.setEsgFactorForm(value, name);
  }

  esgFactorsQuestionChange = (e: any, question_id: string, question_type: string) => {
    const { value } = e.target;
    const newAnswer = {
      answers: value,
      question_id,
      question_type
    }
    const filtered = this.state.esgFactorQuestionAnswers.filter((ans: any) => ans.question_id != question_id);

    this.setState({
      esgFactorQuestionAnswers: [...filtered, newAnswer]
    })
  }

  handleProfileOpenIcon = () => {
    let { openSelectbox } = this.state
    this.setState({ openSelectbox: !openSelectbox });
  };

  handleConsumption = (e: any) => {
    this.setState({ consumptionValue: e.target.value })
    if (e.target.value) {
      this.setState({ consumptErr: false });

    }
  }

  handleCompanyData = (e: any) => {
    let { name, value } = e.target
    if (name === "name") {
      if (value.trim() === '') {
        this.setState({formError: true, btnColor: false})
      } else {
        this.setState({formError: false, btnColor: true})
      }
    } else {
      this.setState({formError: false, btnColor: true})
    }
    let updatedData = { ...this.state.companyData, [name]: value }
    this.setState({companyData: updatedData})
  }

  handlePersonalData = (e: any) => {
    let { name, value } = e.target
    if (value.trim() === '') {
      this.setState({formError: true, btnColor: false})
    } else {
      this.setState({formError: false, btnColor: true})
    }
    let updatedData = {...this.state.personalData, [name]: value}
    this.setState({personalData: updatedData})
  }

  handleProfileImage = (e: any) => {
    const file = e.target.files[0];
    this.makeRadableFile(file)
    this.state.companyData.logo = e.target.value
    const formData = new FormData();
    formData.append('file', file);
    this.setState({ profileImage: file, btnColor: true })
  }

  makeRadableFile = (file: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        profileImageURL: reader.result,
      });
    };
    if (file) {
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };
  handleOnChange = (value?: any, country?: any, e?: any, formattedValue?: any) => {
    let updatedData = { ...this.state.personalData, "contactNo": formattedValue }
    let updatedData1 = { ...this.state.companyData, phoneNo: formattedValue }
    this.setState({ personalData: updatedData, companyData: updatedData1, countryCode: country.dialCode, btnColor: true, selectedCountry: country.countryCode, onchangeState: true })
  }

  handleValidation = (values: any) => {

    const errors: FormErrors = {
      currentPass: "",
      newPass: "",
      confirmNewPass: "",

    };
    const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,}$/;;
    let data = passwordRegex.test(values.newPass)
    
    if (!values.currentPass || values.currentPass.length < 6) {
      errors.currentPass = 'Current password is required';
    }

    if (!values.newPass) {
      errors.newPass = 'New password is required';
    } else if (values.newPass.length > 12) {
      errors.newPass = 'Password must be less than 12 characters';
    } else if (!data) {
      errors.newPass = "Password must have minimum 8 characters, both uppercase and lowercase, at least one digit, and one special character";
    }

    if (!values.confirmNewPass) {
      errors.confirmNewPass = 'Confirm new password is required';
    } else if (values.newPass !== values.confirmNewPass && values.confirmNewPass.length > 0) {
      errors.confirmNewPass = 'Passwords do not match';
    }

    // Remove a key from errors if it is empty
    Object.keys(errors).forEach((key: string) => {
      if (errors[key] === "") { delete errors[key] }
    })

    const errorValues = Object.values(errors);
    // Determine if any key of errors has any error message
    const hasError = errorValues.some((item: string) => item.length > 0);
    this.setState({ error: hasError }); 

    return errors;
  }

  handleShowHide = (id: any) => {
    const { showPass1, showPass2, showPass3 } = this.state
    switch (id) {
      case "1":
        this.setState({ showPass1: !showPass1 })
        break;
      case "2":
        this.setState({ showPass2: !showPass2 })
        break;
      case "3":
        this.setState({ showPass3: !showPass3 })
        break;
    }
  }

  handleSelectBoxOpenIcon = () => {
    let { openSelectbox } = this.state
    this.setState({ openSelectbox: !openSelectbox });
  };

  getCompanyInfoDataApi = () => {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };

    let httpBody = {
      "account_id": this.account_id,
    } as { company_id?: number };

    if (this.account_type === "portfolio_manager") {
      httpBody.company_id = this.organizationid
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.companyInfoCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.CompanyInfoEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.archiveEsgFactorCardApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateCompanyInfoDataApi = () => {
    let { name, website, numberOfEmployees, address, phoneNo } = this.state.companyData
    if(name === '' || website === ''){
      this.setState({formError: true, btnColor: false})
    }else{
      this.setState({formError: false, btnColor: true})
      let { profileImage, countryCode, phoneNumber, onchangeState } = this.state
    let data = phoneNo.replace(`+${this.state.countryCode}`, "")
    const regex = /[^0-9]/g;

    const accountId = this.account_id
    const organizationid = this.organizationid
    const formData = new FormData();
    formData.append('data[company_name]', name);
    formData.append('data[company_website]', website);
    formData.append('data[company_registered_address]', address);
    formData.append('data[number_of_employees]', numberOfEmployees);
    formData.append('data[industry_sector_id]', '');
    formData.append('data[country_code]', countryCode);
    formData.append('data[account_id]', accountId.toString());
    formData.append('data[company_phone_number]', onchangeState ? data.replace(regex, "") : phoneNumber);

    if (profileImage?.name && typeof (profileImage) !== 'string') {
      formData.append('data[company_logo]', profileImage);
    } else {
      formData.append('data[company_logo]', this.state.companylogo);
    }

    if (this.account_type === "portfolio_manager") {
      formData.append("data[company_id]", organizationid.toString())
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCompanyInfoCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.UpdateCompanyInfoEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), {}
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assignApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  fetchEsgFactorDetails = async () => {
    this.setState({ loading: true });
    const cardCatId = this.props.navigation.getParam('cid');
    const cardMId = this.props.navigation.getParam('mid');
    const card_id = await this.props.navigation.getParam("card_id")
    const cardCoreFactId = await this.props.navigation.getParam("coreFacId")

    if (card_id && cardCatId && cardMId) {

      const task_id = this.getTaskId();

      const reqMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiEsgFactorDetailsCallId = reqMsg.messageId;

      reqMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getEsgFactorDetailsEndPoint
      );

      reqMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
          "Content-Type": configJSON.dashboarContentType,
        })
      );

      const dataParams = {
        account_id: this.account_id,
        category_id: parseInt(cardCatId),
        core_factor_id: parseInt(cardCoreFactId),
        material_factor_id: cardMId === null ? "" : parseInt(cardMId),
        card_id: parseInt(card_id),
        task_id: task_id
      }

      reqMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(dataParams)
      );

      reqMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getEsgFactorDetailsApiMethod
      );

      runEngine.sendMessage(reqMsg.id, reqMsg)

    }
  }

  saveEsgFactorDetails() {
    const cardId = this.props.navigation?.getParam('card_id');

    const task_id = this.getTaskId();

    const formData = this.state.esgFactorDetailsForm;
    if (formData.location && formData.timePeriod) {
      this.setState({
        esgFactorDetailsFormError: false
      })

      const dynamicData = this.state.esgFactorQuestionAnswers;

      const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

      this.apiEsgFactorSaveCallId = requestMsg.messageId;

      requestMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.saveEsgFactorSaveEndPoint
      );

      requestMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
          "Content-Type": configJSON.formDataContentType,
        })
      );

      let reqParams = {
        data: {
          account_id: this.account_id,
          card_id: cardId,
          task_id: task_id || this.state.taskId,
          location: formData.location,
          time_period: formData.timePeriod,
          activity: formData.activity,
          user_answers: dynamicData,
        } as { company_id?: number }
      }


      if (this.account_type === "portfolio_manager") {
        reqParams.data.company_id = this.organizationid
      }

      requestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(reqParams));

      requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getEsgFactorDetailsApiMethod);

      runEngine.sendMessage(requestMsg.id, requestMsg);
    } else {
      this.setState({
        esgFactorDetailsFormError: true
      })
    }

  }
  handleTablePageChange = (e: any, page: number) => {
    this.setState({ page }, () => {
      this.getTableData()
    })
  }

  handleTableNumberChange = (e: any,) => {
    this.setState({ perPage: e.target.value, page: 1 }, () => {
      this.getTableData()
    })
  }

  handleFieldLabel = (qitem: any) => {
    if (this.state.locationData) {
      return "Resulting CO2 in kgs"
    } else {
      return (`${qitem.question}`)
    }
  }

  handleOnEsgFactorMoreOption = (value: any, card: any) => {
    switch (value) {
      case "Remove from list":
        this.setState({
          esgCardDelete: {
            confirm: "open",
            card
          }
        })
        break;
      case "Archive":
        this.setState({
          esgCardArchive: {
            confirm: "open",
            card
          }
        })
        break;
      case "Assign to team member":
        this.setState({
          isShowMember: true, cardId: card.id
        })
        break;
      case "Unarchive":
        this.setState({
          esgCardUnArchive: {
            ...this.state.esgCardUnArchive,
            openConfirmation: true,
            cardId: card.id
          }
        })
        break;
      default:
    }
  }
  cardBtnLabel = (card: any) => {
    return card.material_factor === null ? card.core_factor : card.material_factor
  }

  deleteEsgFactorCardCancel = () => {
    this.setState({
      esgCardDelete: {
        confirm: "cancel",
        card: {}
      }
    })
  }

  archiveEsgFactorCardCancel = () => {
    this.setState({
      esgCardArchive: {
        confirm: "cancel",
        card: {}
      }
    })
  }


  deleteEsgFactorCard() {
    const cardId = this.state.esgCardDelete.card.id;
    const accountId = this.account_id;

    if (cardId && accountId) {
      this.setState({
        esgFactorCardDeleteError: false,
        esgCardDelete: {
          confirm: "delete",
        }
      })

      const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.apiEsgFactorDeleteCallId = requestMsg.messageId;
      requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteEsgFactorCardEndPoint
      );
      requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
          "Content-Type": configJSON.formDataContentType,
        })
      );

      const reqParams = {
        account_id: accountId,
        monthly_assessment_card_id: cardId
      }
      requestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(reqParams));
      requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteEsgFactorCardApiMethod);
      runEngine.sendMessage(requestMsg.id, requestMsg);
      this.setState({
        showDeleteAlert: true,
        deleteUpdateStatus: "success",
      })
    } else {
      this.setState({
        esgFactorCardDeleteError: true
      })
    }

  }

  archiveEsgFactorCard() {
    const cardId = this.state.esgCardArchive.card.id;
    const accountId = this.account_id;

    if (cardId && accountId) {
      this.setState({
        esgFactorCardArchiveError: false,
        esgCardArchive: {
          confirm: "archive",
        }
      })

      const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.apiEsgFactorArchiveCallId = requestMsg.messageId;
      requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.archiveEsgFactorCardEndPoint
      );
      requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
          "Content-Type": configJSON.formDataContentType,
        })
      );

      const reqParams = {
        account_id: accountId,
        monthly_assessment_card_id: cardId
      }
      requestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(reqParams));
      requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.archiveEsgFactorCardApiMethod);
      runEngine.sendMessage(requestMsg.id, requestMsg);
      this.setState({
        showArchiveAlert: true,
        archiveUpdateStatus: "success",
      })
    } else {
      this.setState({
        esgFactorCardArchiveError: true
      })
    }

  }
  getOptionLabel(option: any) {
    return `${option.first_name} ${option.last_name}`;
  }

  updatePagination = () => {
    const { page, perPage, totalUser } = this.state;
    const pagesLength = totalUser - 1
    const totalPages = Math.ceil(pagesLength / perPage);
    if (page > totalPages) {
      this.setState({ page: totalPages });
    }
  };

  onAssignCard = (id: number) => {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };

    const httpBody = {
      "account_id": id,
      "card_id": this.state.cardId
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.assignCardApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.assignCardEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assignApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  customFormatter(series: string) {
    return `${series}`;
  }


  customTooltopBar({ series, seriesIndex, dataPointIndex, w }: TooltipContext) {

    const getTitle = {
      "CT": "Certifications",
      "EB": "Ethical Behaviour",
      "AS": "Assurance",
      "CO": "Carbon Offsets",
      "RW": "Renewables"
    } as { [key: string]: string };


    const label = w.globals.labels[dataPointIndex]
    const title = getTitle[label];

    return (
      '<div <div style="display: flex; width: 131px; height: 25px; padding: 8px; justify-content: center; align-items: center; gap: 3px; border-radius: 4px; background: #FFF; box-shadow: 2px 10px 25px 0px rgba(71, 85, 105, 0.08);">' +
      '<span style="color: #34373A; text-align: center; font-family: Lato; font-size: 12px; font-style: normal; font-weight: 700; line-height: 160%;">' + title + '</span> ' +
      '<b style="color: #34373A; text-align: center; font-family: Lato; font-size: 12px; font-style: normal; font-weight: 700; line-height: 160%;">' + series[seriesIndex][dataPointIndex] + "%" + '</b>' +
      '</div>'
    );
  }

  getCustomTooltipdonut({ series, seriesIndex }: TooltipContext) {
    return (
      '<div <div style="display: flex; width: 61.964px; height: 30.982px; padding: 8px; justify-content: center; align-items: center; gap: 3px; border-radius: 4px; background: #FFF; box-shadow: 2px 10px 25px 0px rgba(71, 85, 105, 0.08);">' +
      '<b style="color: #34373A; text-align: center; font-family: Lato; font-size: 12px; font-style: normal; font-weight: 700; line-height: 160%;">' + series[seriesIndex] + "%" + '</b>' +
      '</div>'
    );
  }

  getDescription(inputString: string) {
    if (inputString.length > 120) {
      return inputString.substring(0, 120) + "...";
    } else {
      return inputString;
    }
  }

  setShowSubmitAlert = () => {
    this.setState({
      alert: {
        show: false,
        message: "",
        status: ''
      }
    })
  }

  getFieldOptions2(arr: any) {
    return arr.map((item: { [x: string]: any; id: any; }) => {
      return {

        value: item.param_option_id,
        id: item.param_option_id,
        label: item.param_option_name
      };
    });
  }

  handleKeyPress = (event: any) => {
    const keyCode = event.which || event.keyCode
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 8) {
      event.preventDefault();
    }
  };

  calculateApi = () => {

    this.account_id = JSON.parse(localStorage.getItem("userDetails") as string)?.meta?.id
    const cardId = this.props.navigation.getParam("card_id")
    const account_id = this.account_id
    const formData = this.state.esgFactorDetailsForm;
    const location = this.state.locationData

    if (formData.location && this.state.consumptionValue) {
      this.setState({
        esgFactorDetailsFormError: false,
        consumptErr: false
      })

      const header = {
        "Content-Type": configJSON.dashboarContentType,
      };

      const httpBody = {
        "account_id": account_id,
        "monthly_assessment_card_id": cardId,
        "end_point": this.state.cardEndPoint.replace("/", ""),
        "api_params": {
          "consumption": this.state.consumptionValue,
          "location": location[formData.location - 1]?.param_option_name

        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.calculateApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.calculateEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboarApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.setState({
        esgFactorDetailsFormError: true,
        consumptErr: true
      })
    }

  }

  getemmission() {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };

    const httpBody = {
      "account_id": this.account_id,
      "year": this.state.emmisionyear
    } as { company_id?: number };

    if (this.account_type === "portfolio_manager") {
      httpBody.company_id = this.organizationid
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.emmissionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getemmisionEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  spitDatemonth(value: string) {
    return value.split("-")
  }

  getorgreport() {

    const datetime = this.spitDatemonth(this.state.orgreportmonth)

    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };

    let httpBody = {
      "account_id": this.account_id,
      "month": datetime[0],
      "year": Number(datetime[1])
    } as { company_id?: number };

    if (this.account_type === "portfolio_manager") {
      httpBody.company_id = this.organizationid
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.orgreportApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getorgreportEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getOverallData() {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };

    let httpBody = {
      "account_id": this.account_id,
    } as { company_id?: number };

    if (this.account_type === "portfolio_manager") {
      httpBody.company_id = this.organizationid
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.OverallDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.OverallInfoEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getdscreport() {

    const monthyear = this.spitDatemonth(this.state.descreportmonth)

    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };

    const httpBody = {
      "account_id": this.account_id,
      "month": monthyear[0],
      "year": Number(monthyear[1])
    } as { company_id?: number };

    if (this.account_type === "portfolio_manager") {
      httpBody.company_id = this.organizationid
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.dscreportApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getdscreportEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getenvreport() {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };

    let httpBody = {
      "account_id": this.account_id,
      "year": this.state.envyear
    } as { company_id?: number };

    if (this.account_type === "portfolio_manager") {
      httpBody.company_id = this.organizationid
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.envreportApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getenvreportEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleChangeEmmission = (e: any) => {
    this.setState({ emmisionyear: e.target.value }, () => {
      this.getemmission()
    })
  }

  handleChangeorgreport = (e: any) => {
    this.setState({ orgreportmonth: e.target.value }, () => {
      this.getorgreport()
    })
  }

  handleChangedescreport = (e: any) => {
    this.setState({ descreportmonth: e.target.value }, () => {
      this.getdscreport()
    })
  }

  handleChangeenvreport = (e: any) => {
    this.setState({ envyear: e.target.value }, () => {
      this.getenvreport()
    })
  }

  bootIntercom = () => {
    const userDetailsJson = localStorage.getItem("userDetails") || "{}";
    const userDetails = JSON.parse(userDetailsJson);
    // Generate hash
    const secretKey = 'JJe736GM5XI_eTfarhUlYMFnn-1MgcuAdNSYdHe0'; // an Identity Verification secret key (web)
    const userIdentifier = userDetails.meta.id.toString(); // a UUID to identify your user
    const hash = crypto.createHmac('sha256', secretKey).update(userIdentifier).digest('hex');

    // @ts-ignore
    window.Intercom('boot', {
      api_base: "https://api-iam.intercom.io",
      app_id: "nnczoj2a",
      name: userDetails.meta.first_name,
      email: userDetails.meta.email,
      user_id: userDetails.meta.id,
      user_hash: hash
    });
  }

  navigateToEnergyConsumptionPage = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));

    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ClimatiqBasicEstimate"
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  handleConfirmationModalToUnArchiveCards = (confirmedUnArchive: boolean) => {
    if (!confirmedUnArchive) {
      this.setState({
        esgCardUnArchive: {
          ...this.state.esgCardUnArchive,
          openConfirmation: false,
        }
      });
      return;
    }

    this.setState({
      esgCardUnArchive: {
        ...this.state.esgCardUnArchive,
        openConfirmation: false,
      }
    });
    this.unArchiveEsgFactorCard();
  }

  unArchiveEsgFactorCard = async () => {
    const payload = {
      account_id: this.account_id,
      monthly_assessment_card_id: this.state.esgCardUnArchive.cardId,
    }

    this.apiEsgFactorUnArchiveCallId = await apiCall({
      contentType: configJSON.formDataContentType,
      endPoint: configJSON.unArchiveEsgFactorCardEndPoint,
      method: configJSON.dashboarApiMethodType,
      payload: JSON.stringify(payload),
    })
  }

  handleUnArchiveEsgFactorCardResponse = (response: {
    success: boolean;
    message: string;
  }) => {
    if (!response.success) {
      this.setState({
        esgCardUnArchive: {
          ...this.state.esgCardUnArchive,
          success: false,
        }
      });
      return;
    }

    this.setState({
      esgCardUnArchive: {
        ...this.state.esgCardUnArchive,
        success: true,
      }
    });

    // Call api to fetch latest archived cards
    const category = this.props.navigation?.getParam('category');
    const yearMonth = this.state.cardFilterDate;
    this.fetchEsgFactorData(category, this.state.esgFactorPageNum, yearMonth);
  }

  navigateToShippingFreightPage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
  
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Cfclimatiqshippingfreight"
    );
  
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  
    this.send(message);
  }

  handleToggle = () => {
    this.setState({openOverAllEmission: !this.state.openOverAllEmission});
  };
  handleCloseIcon= () => {
    this.setState({openOverAllEmission:false})
  };
  handleOpenIcon = () => {
    this.setState({openOverAllEmission:true})
  }
  handleToggleOrganization = () => {
    this.setState({openOrganization: !this.state.openOrganization});
  }
  handleCloseOrganizationIcon= () => {
    this.setState({openOrganization:false})
  };
  handleOpenOrganizationIcon = () => {
    this.setState({openOrganization:true})
  }
  handleToggledescreport = () => {
    this.setState({opendescreport: !this.state.opendescreport});
  }
  handleClosedescreportIcon= () => {
    this.setState({opendescreport:false})
  };
  handleOpendescreportIcon = () => {
    this.setState({opendescreport:true})
  }
  handleToggleEnvironment = () => {
    this.setState({openEnvironment: !this.state.openEnvironment});
  }
  handleCloseEnvIcon= () => {
    this.setState({openEnvironment:false})
  };
  handleOpenEnvIcon = () => {
    this.setState({openEnvironment:true})
  }
  navigateToGetStartedPage = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));

    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "GettingStarted"
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }
  closeEditOrganization = () => {
    this.setState({ editInfoModal: false},() => this.getCompanyInfoDataApi())
  }
  handleCloseProfileModal = () => {
    this.setState({ editInfoModal: false},() => this.getProfileData())
  }

  // Customizable Area End

}
